import React from 'react';
import { SiYoutube, SiItchdotio, SiSteam } from 'react-icons/si';
import RoundButton from './RoundButton';
import RoundLinkButton from './RoundLinkButton';

const ProjectLinkButtons = ({itchLink, steamLink, watchVideoLink, isDark, backButtonText}) => {
    return (
        <div className='right-2 lg:right-4 px-4 bottom-5 flex flex-col lg:flex-row-reverse flex-wrap gap-2 absolute duration-700'>
            {
                itchLink &&
                <RoundLinkButton icon={<SiItchdotio size={24} />} link={itchLink} isDark={isDark} />
            }
            {
                steamLink &&
                <RoundLinkButton icon={<SiSteam size={24} />} link={steamLink} isDark={isDark} />
            }
            {
                watchVideoLink &&
                <RoundLinkButton icon={<SiYoutube size={24} />} link={watchVideoLink} isDark={isDark} />
            }

            <div className='lg:hidden block'>
                <RoundButton link={'/projects'} isLeft={true} isDark={isDark} />
            </div>

            <div className='hidden lg:block'>
                <RoundButton text={backButtonText} link={'/projects'} isLeft={true} isDark={isDark} />
            </div>
        </div>
    )
}

export default ProjectLinkButtons