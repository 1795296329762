import React from 'react';
import ProjectCard from './ProjectCard';

import IgboCover from '../media/igbo/cover.jpg';
import EnbaCover from '../media/enba/cover.jpg';
import VoiceVisualizerCover from '../media/voicevisualizer/cover.jpg';
import ArkanoidCover from '../media/arkanoid/cover.jpg';
import TinyDinoRunnerCover from '../media/tinydinorunner/cover.jpg';
import ProceduralCover from '../media/procedural/cover.jpg';
import StormRushCover from '../media/stormrush/cover.jpg';
import ProceduralGenerationCover from '../media/proceduralgeneration/projectcover.jpg';
import FootgunCover from '../media/footgun/cover.jpg';

import useDynamicThemeColor from '../hooks/useDynamicThemeColor';

const Projects = ({ setIsPrimaryColorNavbar }) => {

    useDynamicThemeColor(false);
    setIsPrimaryColorNavbar(false);

    return (
        <div className='bg-secondary w-screen h-screen flex flex-col pt-20 pb-28 px-6 
            lg:pt-32 lg:px-8 overflow-y-scroll'>
            <div className='flex flex-col gap-6'>
                <ProjectCard prefix={"09"} title={"Footgun Underground"} year={"2024"} image={FootgunCover} link={'/projects/footgununderground'} isDark={true}></ProjectCard>
                <ProjectCard prefix={"08"} title={"Procedural Generation"} year={"2023"} image={ProceduralGenerationCover} link={'/projects/proceduralgeneration'}></ProjectCard>
                <ProjectCard prefix={"07"} title={"Project: Storm Rush"} year={"2023"} image={StormRushCover} link={'/projects/stormrush'}></ProjectCard>
                <ProjectCard prefix={"06"} title={"Procedural Creatures"} year={"2022"} image={ProceduralCover} link={'/projects/proceduralcreatures'}></ProjectCard>
                <ProjectCard prefix={"05"} title={"Tiny Dino Runner"} year={"2022"} image={TinyDinoRunnerCover} link={'/projects/tinydinorunner'} isDark={true}></ProjectCard>
                <ProjectCard prefix={"04"} title={"Arkanoid"} year={"2022"} image={ArkanoidCover} link={'/projects/arkanoid'} ></ProjectCard>
                <ProjectCard prefix={"03"} title={"Voice Visualizer"} year={"2021"} image={VoiceVisualizerCover} link={'/projects/voicevisualizer'} ></ProjectCard>
                <ProjectCard prefix={"02"} title={"Enba"} year={"2020"} image={EnbaCover} link={'/projects/enba'}></ProjectCard>
                <ProjectCard prefix={"01"} title={"Igbo"} year={"2019"} image={IgboCover} link={'/projects/igbo'}></ProjectCard>
            </div>
        </div>
    )
}

export default Projects