import React from 'react';
import NormalText from '../text/NormalText';
import MainHeader from '../text/MainHeader';

import { gsap } from 'gsap';
import { useInView } from 'react-intersection-observer';

const ProjectDetailsCard = ({ title = null, paragaph1 = null, paragaph2 = null, image = null }) => {
    const headerRef = React.useRef(null);
    const text1Ref = React.useRef(null);
    const text2Ref = React.useRef(null);
    const imageRef = React.useRef(null);

    const { ref: headerContainerRef, inView: headerContainerVisible } = useInView();
    const [headerVisible, setHeaderVisible] = React.useState(false);

    const { ref: text1ContainerRef, inView: text1ContainerVisible } = useInView();
    const [text1Visible, setText1Visible] = React.useState(false);

    const { ref: text2ContainerRef, inView: text2ContainerVisible } = useInView();
    const [text2Visible, setText2Visible] = React.useState(false);

    const { ref: imageContainerRef, inView: imageContainerVisible } = useInView();
    const [imageVisible, setImageVisible] = React.useState(false);

    React.useEffect(() => {
        if (!headerVisible && headerContainerVisible) {
            gsap.to(headerRef.current, { duration: 1, opacity: '100%' })
            setHeaderVisible(true);
        }

        if (!text1Visible && text1ContainerVisible) {
            gsap.to(text1Ref.current, { duration: 1, opacity: '100%' })
            setText1Visible(true);
        }

        if (!text2Visible && text2ContainerVisible) {
            gsap.to(text2Ref.current, { duration: 1, opacity: '100%' })
            setText2Visible(true);
        }

        if (!imageVisible && imageContainerVisible) {
            gsap.to(imageRef.current, { duration: 1, opacity: '100%' })
            setImageVisible(true);
        }
    }, [headerContainerVisible, text1ContainerVisible, text2ContainerVisible, imageContainerVisible])

    return (
        <>
            {
                (title !== null || paragaph1 !== null || paragaph2 !== null || image !== null) &&
                <div className='w-full overflow-y-auto pt-16 lg:pt-0 lg:h-screen lg:flex lg:snap-start lg:items-center lg:justify-center lg:snap-always'>

                    <div className='lg:flex lg:flex-row lg:justify-center lg:max-w-7xl'>

                        <div className='lg:w-1/2'>
                            <div className='lg:w-full lg:max-w-2xl lg:pr-8'>
                                {
                                    title !== null &&
                                    <div ref={headerContainerRef}>
                                        <div ref={headerRef} className='opacity-0'>
                                            <MainHeader text={title} />
                                        </div>
                                    </div>
                                }

                                {
                                    paragaph1 !== null &&
                                    <div ref={text1ContainerRef}>
                                        <div ref={text1Ref} className='opacity-0'>
                                            <NormalText text={paragaph1} />
                                        </div>
                                    </div>
                                }

                                {
                                    paragaph2 !== null &&
                                    <div ref={text2ContainerRef}>
                                        <div ref={text2Ref} className='opacity-0'>
                                            <NormalText text={paragaph2} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        {
                            image !== null &&
                            <div ref={imageContainerRef} className='my-6 lg:m-0 flex flex-row justify-center items-center lg:w-1/2 duration-700'>
                                <div ref={imageRef} className='opacity-0'>
                                    <img src={image} alt='' className='w-full max-w-lg lg:max-w-none rounded-md duration-700 shadow-md' />
                                </div>
                            </div>
                        }
                    </div>

                </div>
            }
        </>
    )
}

export default ProjectDetailsCard