import React from 'react'

const SmallText = ({ text, additionalStyle }) => {
    return (
        <p className={`text-sm lg:text-base font-mono duration-700 ${additionalStyle}`}>
            {text}
        </p>
    )
}

export default SmallText