import React from 'react';
import emailjs from '@emailjs/browser';
import PageHeader from '../text/PageHeader';
import { gsap } from 'gsap';
import useDynamicThemeColor from '../hooks/useDynamicThemeColor';
import { content } from './content'; // Übersetzungen
import EmailCard from './EmailCard';

const Contact = ({ language, setIsPrimaryColorNavbar }) => {

    useDynamicThemeColor(true);
    setIsPrimaryColorNavbar(false);

    // Content übersetzt in allen verfügbaren Sprachen
    let currentContent = {}
    language === 'english' ? (currentContent = content.english) : (currentContent = content.german);

    return (
        <div className='bg-secondary w-screen h-screen flex flex-col pt-20 pb-28 px-6 
        lg:pt-32 lg:px-8 text-left overflow-y-scroll'>

            {/* ====== Kontakt Beschreibung ====== */}
            <div className='w-full flex flex-row justify-center'>

                <div className='w-full text-white pb-6 px-4 lg:px-0 max-w-7xl'>
                    <PageHeader text={currentContent.contact} />
                    <EmailCard text={"contact@kinychanchan.com"} />
                </div>

            </div>
        </div>
    )
}

export default Contact