import React from 'react';
import { Link } from "react-router-dom";

const ProjectCard = ({ title, year, image, link, isDark = false }) => {
    return (
        <Link to={link}>
            <div className='w-full md:flex project_card_image'>
                <span className='hidden md:flex w-1/6 leading-[70%] 
                font-extrabold font-sans uppercase text-[5vw] duration-700 text-[#737373]'>
                    {year}
                </span>

                <div className='w-full h-52 md:w-5/6 md:h-64 rounded-xl shadow-md duration-500 relative overflow-hidden'>

                    <img src={image} alt='' className='w-full h-full rounded-xl object-cover object-center duration-700' />

                    <div className='absolute bottom-5 left-4 pr-4 w-full flex'>

                        <h2 className={`font-800 ${isDark ? 'text-primary' : 'text-white'} opacity-100
                        font-sans uppercase 2xl:text-6xl xl:text-5xl lg:text-4xl text-2xl 
                        font-black duration-700 lg:opacity-0 truncate`}>
                            {title}
                        </h2>

                    </div>
                </div>
            </div>
        </Link>
    )
}

export default ProjectCard