import React from 'react';
import NormalText from '../text/NormalText';
import MainHeader from '../text/MainHeader';

const AboutCard = ({ title, text = null, gridstyle }) => {
    return (
        <div className={`text-black ${gridstyle} w-full
        duration-700 text-left p-4 ${text == null ? 'flex flex-col justify-center' : ''}`}>

            {/* <p className={`uppercase font-sans font-700 pb-1 duration-700
                ${text == null ? 'text-7xl lg:text-9xl mx-auto' : 'text-4xl lg:text-5xl -ml-1'}`}>
                {title}
            </p> */}

            <MainHeader text={title} additionalStyle={text === null ? 'mx-auto text-primary' : ''}/>

            {
                text !== null &&
                <NormalText text={text} />
            }

        </div>
    )
}

export default AboutCard