export default function useDynamicThemeColor(isPrimary) {

    if (isPrimary)
    {
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#3BBC38');
        document.body.style.backgroundColor = '#3BBC38';
    }
    else
    {
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#171717');
        document.body.style.backgroundColor = '#171717';
    }
}