import React from 'react'

const MainHeader = ({ text, additionalStyle }) => {
    return (
        <h2 className={`font-800 font-sans uppercase 2xl:text-6xl xl:text-5xl lg:text-4xl text-2xl font-black duration-700 ${additionalStyle}`}>
            {text}
        </h2>
    )
}

export default MainHeader