import React from 'react';
import { Link } from "react-router-dom";

const NavButtonDesktop = ({ text, link, icon = null, href = null, isPrimary }) => {
  return (
    <li>
      {
        href === null ?
          <Link to={link} className={`${isPrimary ? 'text-primary bg-primary' : 'text-white bg-white'} hover:bg-opacity-30 bg-opacity-10 
            items-center relative h-7 font-mono tracking-wider pt-0.5 first::pt-0 uppercase text-2xs font-500 
            padding-huge duration-200 px-4 rounded flex justify-center flex-row`}>
            <div>
              {icon === null && text}
              {icon !== null && icon}
            </div>
          </Link>
          :
          <a href={href} target='_blank' className={`${isPrimary ? 'text-primary bg-primary' : 'text-white bg-white'} hover:bg-opacity-30 bg-opacity-10 
            items-center relative h-7 font-mono tracking-wider pt-0.5 first::pt-0 uppercase text-2xs font-500 
            padding-huge duration-200 px-4 rounded flex justify-center flex-row`}>
            <div>
              {icon === null && text}
              {icon !== null && icon}
            </div>
          </a>
      }
    </li>
  )
}

export default NavButtonDesktop