import React from 'react'

const SubHeader = ({ text, additionalStyle }) => {
    return (
        <h3 className={`font-800 font-sans uppercase 2xl:text-4xl xl:text-3xl lg:text-2xl text-lg font-black duration-700 ${additionalStyle}`}>
            {text}
        </h3>
    )
}

export default SubHeader