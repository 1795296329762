import React from 'react';
import { gsap } from 'gsap';
import { useInView } from 'react-intersection-observer';
import useEmblaCarousel from 'embla-carousel-react';

import Kiny1 from '../media/kiny-web-1-1000x1000.png';
import Kiny2 from '../media/kiny-web-2-1000x1000.png';
import Kiny3 from '../media/kiny-web-3-1000x1000.png';
import BackgroundKiny from '../media/backgroundkiny.png';

import EmblaSlide from './EmblaSlide';
import EmblaButton from './EmblaButton';
import EmblaDesktop from './EmblaDesktop';

import useDynamicThemeColor from '../hooks/useDynamicThemeColor';
import { content } from './content'; // Übersetzungen

const Embla = ({ language, setIsPrimaryColorNavbar }) => {

    useDynamicThemeColor(true);
    React.useEffect(() => {
        setIsPrimaryColorNavbar(false);
    }, [])

    // ============================== Mobile ==============================
    const [emblaRef, emblaApi] = useEmblaCarousel({
        loop: false, draggable: true, axis: 'x',
        // breakpoints: { '(min-width: 1024px)': { axis: 'y' } }
    });
    const [index, setIndex] = React.useState(0); // Aktueller Index des Carousels
    const carouselImageRef = React.useRef(null); // Referenz zum Carousel Image
    const [carouselImage, setCarouselImage] = React.useState("./media/kinynormal.png"); // Bild des Carousel Images

    const scrollTo = React.useCallback((index) => {
        if (!emblaApi) return;
        // console.log("Scroll To");
        emblaApi.scrollTo(index);
    })

    /* Wird aufgerufen sobald der index des Carousels sich ändert, also nach handleSelect. 
    Wartet etwas länger als die fadeout Animation, wechselt das Bild und animiert anschließend 
    einen fadein*/
    const onSelect = React.useCallback(() => {
        if (!emblaApi) return;

        gsap.to(carouselImageRef.current, { duration: .3, opacity: 0 });
        setIndex(() => emblaApi.selectedScrollSnap());
        // console.log(emblaApi.selectedScrollSnap()); // Debug

        const waitForAnimationEnd = async () => {
            await timeout(500);
            let cindex = Number(emblaApi.selectedScrollSnap());

            switch (cindex) {
                case 0:
                    setCarouselImage(Kiny1);
                    break;
                case 1:
                    setCarouselImage(Kiny2);
                    break;
                case 2:
                    setCarouselImage(Kiny3);
                    break;
                default:
                    console.warn("Carousel Image not found at Index: " + index);
                    break;
            }

            gsap.to(carouselImageRef.current, { duration: .3, opacity: 1 });
        }

        waitForAnimationEnd();

    }, [emblaApi]);

    React.useEffect(() => {
        if (!emblaApi) return;
        onSelect();
        emblaApi.on("select", onSelect);
    }, [emblaApi, onSelect]);

    /*Delay funcion die ein Promise wieder gibt damit auf diesen 
    gewartet werden kann*/
    function timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }

    // ============================== Desktop ==============================
    /*Desktop Update Kiny Image*/
    const { ref: slideRef1, inView: slideVisible1 } = useInView();
    const { ref: slideRef2, inView: slideVisible2 } = useInView();
    const { ref: slideRef3, inView: slideVisible3 } = useInView();

    React.useEffect(() => {
        if (
            (slideVisible1 && !slideVisible2) ||
            (slideVisible2 && !slideVisible1 && !slideVisible3) ||
            (slideVisible3 && !slideVisible2)
        ) {
            gsap.to(carouselImageRef.current, { duration: .2, opacity: 0 });

            const waitForAnimationEnd = async () => {
                await timeout(300);

                if (slideVisible1 && !slideVisible2) {
                    setIndex(0);
                    setCarouselImage(Kiny1);
                }
                else if (slideVisible2 && !slideVisible1 && !slideVisible3) {
                    setIndex(1);
                    setCarouselImage(Kiny2);
                }
                else if (slideVisible3 && !slideVisible2) {
                    setIndex(2);
                    setCarouselImage(Kiny3);
                }

                gsap.to(carouselImageRef.current, { duration: .2, opacity: 1 });
            }

            waitForAnimationEnd();
        }
    }, [slideVisible1, slideVisible2, slideVisible3])

    // Content übersetzt in allen verfügbaren Sprachen
    let curerntContent = {};
    language === 'english' ? (curerntContent = content.english) : (curerntContent = content.german);

    return (
        <div>
            {/* Mobile - Embla mit Buttons */}
            <div className='embla z-10 lg:hidden' ref={emblaRef}>

                <div className='embla__container z-10'>
                    <EmblaSlide
                        title={curerntContent.home_title1}
                        text={curerntContent.home_text1}
                        buttonText={curerntContent.home_button1}
                        link={'/about'}
                    />
                    <EmblaSlide
                        title={curerntContent.home_title2}
                        text={curerntContent.home_text2}
                        buttonText={curerntContent.home_button2}
                        link={'/projects'}
                    />
                    <EmblaSlide
                        title={curerntContent.home_title3}
                        text={curerntContent.home_text3}
                        buttonText={curerntContent.home_button3}
                        link={'/contact'}
                    />
                </div>

                <div className='absolute z-10 w-full bottom-0 p-4 grid grid-cols-3 gap-3
                    lg:hidden'>
                    <EmblaButton text={curerntContent.home_things} isSelected={index === 0} onClick={() => scrollTo(0)} />
                    <EmblaButton text={curerntContent.home_games} isSelected={index === 1} onClick={() => scrollTo(1)} />
                    <EmblaButton text={curerntContent.home_web} isSelected={index === 2} onClick={() => scrollTo(2)} />
                </div>

            </div>

            {/* Desktop - Embla Buttons Preview */}
            <div className='fixed h-full flex flex-col justify-center gap-3 left-3'>
                <EmblaButton isSelected={slideVisible1}></EmblaButton>
                <EmblaButton isSelected={slideVisible2}></EmblaButton>
                <EmblaButton isSelected={slideVisible3}></EmblaButton>
            </div>

            {/* Desktop - Embla Vertical*/}
            <div className='hidden lg:block overflow-hidden'>
                <EmblaDesktop
                    slideRef1={slideRef1}
                    slideRef2={slideRef2}
                    slideRef3={slideRef3}
                    language={language}
                />
            </div>

            {/*Kiny Embla Image mit Gradient */}
            <div className='embla_last_slide_gradient absolute w-full bottom-0 z-0 lg:hidden'></div>

            <div className='absolute inset-0 flex z-[-1] md:flex-row-reverse duration-700'>
                <img ref={carouselImageRef} src={carouselImage}
                    className='absolute w-[100vmin] lg:w-[90vmin] bottom-0 z-[-1] lg:right-5 lg:fixed'
                    alt=''
                />
            </div>

            <div className='absolute inset-0 z-[-2] flex flex-row'>
                <img src={BackgroundKiny}
                    className='absolute w-full h-auto bottom-0'
                />
            </div>

        </div>
    )
}

export default Embla