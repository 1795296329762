import React from 'react';

const KinyLogo = ({width = "1000px", height="1000px", fill="#1D1D1B", stroke="#1D1D1B"}) => {
    return (
        <svg
            width={width}
            height={height}
            fill={fill}
            stroke={stroke}
            viewBox="0 0 1000 1000"
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
        >

            <g id="kinylogosvg">
                <path className='st2' d="M943.2,67.8c0,0-296.7,360.4-360.4,360.4c-38.2,0-215.9-215.9-215.9-215.9s188.1,75.5,216.4,72.3
		            C635.4,278.9,943.2,67.8,943.2,67.8z"/>
                <path className='st2' d="M943.2,931.7c0,0-296.7-360.4-360.4-360.4c-38.2,0-215.9,215.9-215.9,215.9s188.1-75.5,216.4-72.3
		            C635.4,720.6,943.2,931.7,943.2,931.7z"/>
                <path className='st2' d="M489,500c0,76.4-432,432-432,432s216-371.6,216-432C273,439.6,57,67.8,57,67.8S489,423.6,489,500z" />
            </g>

        </svg>
    )
}

export default KinyLogo