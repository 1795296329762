import React from 'react';
import { gsap } from 'gsap';
import { useInView } from 'react-intersection-observer';
import useDynamicThemeColor from '../hooks/useDynamicThemeColor';
import ProjectDetailsCard from './ProjectDetailsCard';

import DynamicMainHeader from '../text/DynamicMainHeader';
import DynamicHomeHeader from '../text/DynamicHomeHeader';
import ProjectLinkButtons from './ProjectLinkButtons';

import { content } from './content'; // Übersetzungen

const ProjectDetails = ({ projectTitle, releaseYear, status, projectHeroImage,
    title1 = null, paragaph1_1, paragaph1_2, image1,
    title2 = null, paragaph2_1, paragaph2_2, image2,
    title3 = null, paragaph3_1, paragaph3_2, image3,
    watchVideoLink = null, itchLink = null, steamLink = null,
    language, duration, isDark = false, platform, setIsPrimaryColorNavbar
}) => {

    useDynamicThemeColor(false);
    setIsPrimaryColorNavbar(isDark);

    // Project Title Animation
    const projectTitleRef = React.useRef(null);
    const { ref: projectTitleContainerRef, inView: projectTitleVisibleView } = useInView();
    const [projectTitleVisible, setProjectTitleVisible] = React.useState(false);

    React.useEffect(() => {
        if (!projectTitleVisible && projectTitleVisibleView) {
            gsap.to(projectTitleRef.current, { duration: 0.5, translateX: '0vw' });
            setProjectTitleVisible(true);
        }

    }, [projectTitleVisibleView])

    // Project Stats Animation
    const statsHomeHeader1 = React.useRef(null);
    const statsHomeHeader2 = React.useRef(null);
    const statsHomeHeader3 = React.useRef(null);
    const statsHomeHeader4 = React.useRef(null);
    const statsMainHeader1 = React.useRef(null);
    const statsMainHeader2 = React.useRef(null);
    const statsMainHeader3 = React.useRef(null);
    const statsMainHeader4 = React.useRef(null);

    const { ref: statsContainerRef, inView: statsContainerVisible } = useInView();
    const [statsVisible, setStatsVisible] = React.useState(false);

    React.useEffect(() => {
        if (!statsVisible && statsContainerVisible) {
            gsap.to(statsHomeHeader1.current, { duration: 1, translateX: '0vw' });
            gsap.to(statsHomeHeader2.current, { duration: 1, translateX: '0vw' });
            gsap.to(statsHomeHeader3.current, { duration: 1, translateX: '0vw' });
            gsap.to(statsHomeHeader4.current, { duration: 1, translateX: '0vw' });
            gsap.to(statsMainHeader1.current, { duration: 3, opacity: '100%' });
            gsap.to(statsMainHeader2.current, { duration: 3, opacity: '100%' });
            gsap.to(statsMainHeader3.current, { duration: 3, opacity: '100%' });
            gsap.to(statsMainHeader4.current, { duration: 3, opacity: '100%' });
            setStatsVisible(true);
        }
    }, [statsContainerVisible])

    // Content übersetzt in allen verfügbaren Sprachen
    let currentContent = {}
    language === 'english' ? (currentContent = content.english) : (currentContent = content.german);

    return (
        <div className='bg-secondary text-white text-left'>

            {/* Scroll Container */}
            <div className='w-screen h-screen overflow-y-scroll snap-y snap-mandatory scroll-smooth'>

                {/* ====== Project Titlescreen ======*/}
                <div ref={projectTitleContainerRef} className={`w-full h-screen ${projectHeroImage} bg-cover bg-center scroll-mp-6 lg:scroll-my-0 snap-start overflow-hidden`}>

                    <div ref={projectTitleRef} className='h-full w-full flex justify-center items-center translate-x-[100vw]'>
                        <h2 className={`font-800 ${isDark ? 'text-black' : 'text-white'} 
                            text-[5vmax] font-extrabold uppercase text-center duration-700`}>
                            {projectTitle}
                        </h2>
                    </div>

                </div>

                {/* ====== Project Stats ====== */}
                <div className='w-full h-screen scroll-py-6 lg:scroll-my-0 snap-start'>
                    <div className='w-full h-full py-6 px-4 sm:pl-[10vmin] md:pl-32 duration-700 flex flex-col justify-center bg-white bg-opacity-10 text-white'>

                        <div ref={statsContainerRef}>
                            <div ref={statsHomeHeader1} className='translate-x-[-100vw]'>
                                <DynamicHomeHeader text={currentContent.pjd_releaseYear} additionalStyle={'text-primary pb-[0.1vmin]'} />
                            </div>
                            <div ref={statsMainHeader1} className='opacity-0'>
                                <DynamicMainHeader text={releaseYear} additionalStyle={'pb-[3vmin]'} />
                            </div>
                            <div ref={statsHomeHeader2} className='translate-x-[-100vw]'>
                                <DynamicHomeHeader text={currentContent.pjd_duration} additionalStyle={'text-primary pb-[0.1vmin]'} />
                            </div>
                            <div ref={statsMainHeader2} className='opacity-0'>
                                <DynamicMainHeader text={duration} additionalStyle={'pb-[3vmin]'} />
                            </div>
                            <div ref={statsHomeHeader3} className='translate-x-[-100vw]'>
                                <DynamicHomeHeader text={currentContent.pjd_platform} additionalStyle={'text-primary pb-[0.1vmin]'} />
                            </div>
                            <div ref={statsMainHeader3} className='opacity-0'>
                                <DynamicMainHeader text={platform} additionalStyle={'pb-[3vmin]'} />
                            </div>
                            <div ref={statsHomeHeader4} className='translate-x-[-100vw]'>
                                <DynamicHomeHeader text={currentContent.pjd_status} additionalStyle={'text-primary pb-[0.1vmin]'} />
                            </div>
                            <div ref={statsMainHeader4} className='opacity-0'>
                                <DynamicMainHeader text={status} />
                            </div>
                        </div>

                    </div>
                </div>

                {/* ====== Projectdescription Cards ====== */}
                {/* Desktop */}
                {
                    (title1 !== null) &&
                    <div className='hidden lg:block px-4 py-6 lg:py-0'>
                        <ProjectDetailsCard
                            title={title1}
                            paragaph1={paragaph1_1}
                            paragaph2={paragaph1_2}
                            image={image1}
                        />
                    </div>
                }

                {
                    (title2 !== null) &&
                    <div className='hidden lg:block px-4 py-6 lg:py-0'>
                        <ProjectDetailsCard
                            title={title2}
                            paragaph1={paragaph2_1}
                            paragaph2={paragaph2_2}
                            image={image2}
                        />
                    </div>
                }

                {
                    (title3 !== null) &&
                    <div className='hidden lg:block px-4 py-6 lg:py-0'>
                        <ProjectDetailsCard
                            title={title3}
                            paragaph1={paragaph3_1}
                            paragaph2={paragaph3_2}
                            image={image3}
                        />
                    </div>
                }

                {/* Mobile */}
                {
                    (title1 !== null) &&
                    <div className='w-full lg:hidden px-4 scroll-py-6 snap-start min-h-screen'>
                        <ProjectDetailsCard
                            title={title1}
                            paragaph1={paragaph1_1}
                            paragaph2={paragaph1_2}
                            image={image1}
                        />
                    </div>
                }

                {
                    (title2 !== null) &&
                    <div className='w-full lg:hidden px-4 scroll-py-6 snap-start min-h-screen'>
                        <ProjectDetailsCard
                            title={title2}
                            paragaph1={paragaph2_1}
                            paragaph2={paragaph2_2}
                            image={image2}
                        />
                    </div>
                }

                {
                    (title3 !== null) &&
                    <div className='w-full lg:hidden px-4 scroll-pt-6 snap-start min-h-screen'>
                        <ProjectDetailsCard
                            title={title3}
                            paragaph1={paragaph3_1}
                            paragaph2={paragaph3_2}
                            image={image3}
                        />
                    </div>
                }

                <div className='w-full h-28 lg:hidden snap-start' />

                {/* Link Buttons */}
                <ProjectLinkButtons
                    itchLink={itchLink}
                    steamLink={steamLink}
                    watchVideoLink={watchVideoLink}
                    backButtonText={currentContent.pjd_backbutton}
                    isDark={isDark}
                />

            </div>

        </div>
    )
}

export default ProjectDetails