const content = {
    english: {
        // ====== Embla ====== 
        home_title1: 'Kiny does things',
        home_text1: 'Software development with passion. Specialized in gaming in Unity. Always looking for new projects and always creating.',
        home_button1: 'About me',
        home_title2: 'Games for the world',
        home_text2: `Over 6 years of experience with the Unity Engine. From 2D, 3D to multiplayer virtual reality projects, it's all here. No matter if web or standalone.`,
        home_button2: 'Projects',
        home_title3: 'World Wide Web',
        home_text3: 'Over 2 years of experience in web development. Homepages for everyone. Smart Contracts and Web3 implementations for artists and enthusiasts. The perfect introduction to the NFT community.',
        home_button3: 'Contact',
        home_things: '#Things',
        home_games: '#Games',
        home_web: '#Web',

        // ====== Project Details ====== 
        // General
        pjd_status_finished: 'Finished',
        pjd_status_development: 'In development',
        pjd_title_project: 'About project',
        pjd_title_learning: 'Learning',
        pjd_title_overview: 'Overview',
        pjd_title_background: 'Background',
        pjd_title_recap: 'Review',
        pjd_title_result: 'Result',

        pjd_backbutton: 'Projects',
        pjd_platform: 'Platform:',
        pjd_releaseYear: 'Release year:',
        pjd_status: 'Status:',
        pjd_duration: 'Duration:',

        // Igbo
        pjd_igbo_p11: '"Igbo" is the first game and programming project I ever realized. In 2017 I started teaching programming with the help of Unity. In 2018, I started developing the Hollow Knight inspired side-scroller. You play a bush trapped in an underground jungle. The goal is to escape from it.',
        pjd_igbo_p12: 'The project consists of basic mechanics like attacking and jumping. An attack can be performed horizontally or vertically using the movement keys. Players can dash in any direction and perform small wall jumps.',
        pjd_igbo_p21: `In the beginning, the learning curve is always particularly high. That's why the focus of this project was to learn as much as possible in each area. This includes learning to program, getting to know Unity and gaining a general feeling for game development.`,
        pjd_igbo_p22: 'The goal was to finish the game in order to upload it. This put my endurance to the test, with the ulterior motive of finding out if I could work longer on a project. That turned out to be especially important later.',

        // Enba
        pjd_enba_p11: '"Enba" is a pixel-art style side-scroller in which you play a bell-like character. The goal is to find his little bell friend and get to the end of the small world with him.',
        pjd_enba_p12: 'Along the way, you solve simple puzzles with your little friend and fight against evil creatures. The mechanics are an improvement from the predecessor project "Igbo" and are differentiated by the fact that you can use your little friend as a projectile and teleport point.',
        pjd_enba_p21: 'With this project I wanted to incorporate all aspects from the last project. That means gaining more experience in programming, getting to know Unity better and finding my own workflow.',
        pjd_enba_p22: 'I was able to improve my Unity and C# skills a lot. Meanwhile, I was able to get a much better feel for the project and also realized how important documentation is. Unity started to feel familiar and I was able to find my way around better.',

        // Voice Visualizer
        pjd_voicev_p11: '"Voice Visualizer" is a tool I wrote for streaming on Twitch. It is a webcam alternative that can be inserted into streaming software like OBS Studio.',
        pjd_voicev_p12: 'Instead of the camera, you have a picture that pulses to the microphone input. In addition, you can insert further images, which are displayed for a few seconds by pressing a button. For example, you can use emotes to simulate facial expressions and convey emotions to the viewers.',
        pjd_voicev_p21: `When I started streaming on Twitch, I was looking for a way to not show my face, as my privacy is important to me, but still offer the viewers something visual for the audio. So I came up with the concept of scaling an image by the sound of the voice. I developed the prototype and my problem was solved.`,
        pjd_voicev_p22: 'Later I came up with the idea to extend the tool and develop a UI for it so that other people could use it as well. I wanted to share my tool with the world and decided to publish it on Steam.',
        pjd_voicev_p31: `For the first time, I found myself in a real project cycle. The software had to be as user-friendly as possible. Also, good documentation is needed so that getting started is not a total disaster for the users.`,
        pjd_voicev_p32: 'That turned out to be a big challenge. For the standpoint at the time, I got the best out of it. The project opened my eyes to details. I really enjoyed working with Steam as well as external testers and internalized any feedback.',

        // Arkanoid
        pjd_arkanoid_p11: 'Many people are familiar with the classic game "Arkanoid". For my studies I had to fulfill the task to develop an "Arkanoid" clone.',
        pjd_arkanoid_p12: 'There were fixed specifications about what features the game should have. These include: some power-ups, a score that increases when blocks are destroyed and lives that are subtracted when you lose the ball.',
        pjd_arkanoid_p21: 'Unlike the other projects, this one was finished pretty quickly, as I was already able to gain some experience in Unity and improve my programming skills a lot at that point. It was very helpful to know what exactly to include in the game.',
        pjd_arkanoid_p22: `This showed me how important it is to have a concept and plan projects. Even if something changes during the development, you should keep a record of it.`,

        // Tiny Dino Runner
        pjd_tinydino_p11: 'Many people know the Chrome Runner. The "Tiny Dino Runner" is a Web3 based game in which you play a tiny dino. This dino runs faster than Forrest Gump and dodges dangerous cacti and pterosaurs.',
        pjd_tinydino_p12: 'Players can achieve new high scores and compare them with other players on a leaderboard. You are verified via your cryptowallet.',
        pjd_tinydino_p21: '"Tiny Dinos" is an NFT collection with a wonderful community. Within the community, jokes were made that the Tiny Dino would replace the Chrome Dino. As a result, I took the initiative and started to make the joke a reality.',
        pjd_tinydino_p22: `Since it's a Web3 community, of course I had to live up to that. So I had to teach myself how to get access to crypto wallets and smart contracts within the game. In addition, there was a leaderboard, which the DAO used to run raffles.`,
        pjd_tinydino_p31: 'In the meantime, the verification via cryptowallet and the leaderboard have been disabled. Since the traffic has dropped significantly, it is no longer worth keeping the servers online. However, you can still play "Tiny Dino Runner". An update is already being planned, though.',
        pjd_tinydino_p32: 'This project is one of my favorites to this day. The technical effort was not worth mentioning. The communication and the great feedback from the community made the project so remarkable, which is why it will always remain something special for me.',

        // Procedural Creatures/Animations
        pjd_proceduralcreatures_p11: 'In Procedural Creatures you can navigate animated creatures through uneven terrain. The number of legs can be changed. These creatures can be created independently in Unity, but you can also upload existing models.',
        pjd_proceduralcreatures_p12: 'The main difference to conventional animations is that they are not fixed by key frames. The animations in this program are calculated in real time and adjust the behavior accordingly.',
        pjd_proceduralcreatures_p21: 'At the end of the semester, we were allowed to choose a topic and work on it as part of the course "Games Engines". Despite known challenges, I decided to tackle the topic.',
        pjd_proceduralcreatures_p22: 'The goal was to demonstrate procedural animations. However, I wanted to make the script reusable, support a different number of legs and support imported models as well.',
        pjd_proceduralcreatures_p31: 'Without the right concept, this project would not have been possible. With good research and a solid plan, I was able to optimize the workflow so that I had enough time to build a level and optimize movements towards the end.',
        pjd_proceduralcreatures_p32: 'Once again, it turned out that the right planning makes everything possible. In addition, the project has gained attention half a year later. It was part of video recordings of the university and received extremely positive feedback.',

        // Stormrush
        pjd_stormrush_p11: `Project: Storm Rush is a virtual reality multiplayer game in which players must protect their city from monsters. It is a wave defense game in which waves of enemies destroy the town's mana-plants. Players must stop them from doing so and receive new weapons to fight with.`,
        pjd_stormrush_p12: 'At a wishing well, players can exchange coins they receive for random weapons. Additionally, weapons can be fused together to create stronger weapons. If the players die, they have to run to the graveyard to revive themselves.',
        pjd_stormrush_p21: 'This project was created as part of the "Labor Games" course and is designed to incorporate one major theme. In this case it is Virtual Reality and Networking. My study partner and I really wanted to implement a VR project, but that was not enough and networking was added.',
        pjd_stormrush_p22: `The challenges were virtual reality and networking (who would have thought?). At the time, no one from our two person team had any experience in this area. The bar was high and we didn't know if we could master these two big topics.`,
        pjd_stormrush_p31: 'After teaching ourselves the VR and networking basics, it turned out that the real challenge was networking. We also had to take motion sickness into account. We created settings for different types of motion to simplify the game experience. Haptic feedback was also needed to make the experience as immersive as possible.',
        pjd_stormrush_p32: 'Abstraction saved us a lot of work. It was a fun experience to stream the development on Twitch and involve friends who created models for the game. In addition, the feedback from all sides was always positive, which motivated us to keep working.',

        // Procedural Level
        pjd_proceduralgeneration_p11: 'This project is dedicated to the development of a tool for procedural generation of 2D levels, including landscapes and dungeons that can be interconnected. These levels and level sequences can be regenerated or reconfigured using seeds.',
        pjd_proceduralgeneration_p12: `Within these levels, additional objects such as houses, trees, and more can be created to breathe more life into the levels. It was crucial to provide the tool with maximum flexibility for developers. All of this was developed as part of my bachelor's thesis.`,
        pjd_proceduralgeneration_p21: `The development of the tool was marked by challenges. On the one hand, it required dealing with the complex field of algorithms, my "arch-nemesis." On the other hand, it necessitated exploring new ways to generate specific patterns and integrate them into a central system.`,
        pjd_proceduralgeneration_p22: `Months later, I am reaping the benefits of my thorough documentation. The tool is now being successfully utilized in a new project and exclusively expanded for the generation of landscapes.`,

        // Footgun
        pjd_footgun_p11: 'Footgun is an action roguelike game where players navigate through unique levels and fight their way to the next station by defeating monsters with their soccer ball.',
        pjd_footgun_p12: 'Various balls with different types of power-ups can be combined, allowing players to develop their own individual playstyle.',
        pjd_footgun_p21: 'In 2023, I joined the indie studio Turtle Knight Games, which is involved in the development of the game "Footgun Underground". This marks my first involvement with a larger team in the development of a game.',


        // ====== Navbar ======
        menu_home: 'home',
        menu_projects: 'projects',
        menu_about: 'about',
        menu_contact: 'contact',
        menu_language: 'language',

        // ====== About ======
        about_pagetitle: 'About me',
        about_title1: 'I am Kiny',
        about_text1: 'a 24 years old software engineering student from Germany. For over 6 years I have been involved in programming various software. I slipped into computer science after a two-year school education in design.',
        about_title2: 'My passion',
        about_text2: 'is programming games and giving free rein to my creativity. Since I was a little boy I was fascinated by video games and imagined what it would be like to create my own game. Unfortunately programming always kept me away from it, until one day I took the first step and just started. Now I have over 6 years of experience and know Unity like the back of my hand. The attraction to learn and try new things makes me stick to this passion. The versatility in game development is inspiring!',
        about_title3: 'Websites',
        about_text3: `I have already created in my school education. Through an internship I found myself liking web development and started learning React and Tailwind. Since then, I've built websites for NFT projects and created some small projects that will never see the light of day. I enjoy working on the front end the most because I can let my creativity run wild.`,
        about_title4: 'Web3',
        about_text4: 'is a new area that I dove into. The possibility of running scripts on the blockchain really appealed to me. In Solidity, I wrote smart contracts for NFT collections and connected them to games or websites. One game was the "Tiny Dino Runner" for a small but mighty community.',
        about_title5: 'Python',
        about_text5: `is not my pet, but the programming language I have used for various bots. From bots for video games to Web3 and AI based chatbots, everything was there. I also like to use Pyhton for web scraping or automations to save time.`,
        about_title6: 'Train like you fight',
        about_text6: 'is the saying I always keep in mind. Constantly improving and learning new things is my lifestyle. Everyone makes mistakes, but the art behind it is to admit them and avoid them the next time. If communication is the key, realism is the hand that opens the door.',
        about_title_skilltree: 'Skill tree',
        about_programming_languages: 'Programming languages',
        about_available_skillpoints: 'Available skill points',
        about_used_skillpoints: 'Used skill points',

        // ====== Contact ======
        contact: 'Contact',

        button_error: 'OK',
        button_success: 'Bring me back',

        // Beispielaufträge
        blist_openbutton: 'Commissions',
        blist_description: 'You can find possible commissions here. If your request is not listed, just contact me. Asking costs nothing. Prices for orders vary depending on the scope.',
        blist_unity_1: '2D game',
        blist_unity_2: '3D game',
        blist_unity_3: 'Multiplayer game',
        blist_unity_4: 'Expansions for existing games',
        blist_unity_5: 'Web3 implementation',

        blist_web2_1: 'Website creation',
        blist_web2_2: 'Website editing',
        blist_web2_3: 'Website expansion',
        blist_web2_4: 'Hosting Support',

        blist_web3_1: 'Smart Contract Creation',
        blist_web3_2: 'Implementation for websites',
        blist_web3_3: 'Implementation for Unity Games',
        blist_web3_4: 'NFT generation with metadata',
        blist_web3_5: 'IPFS Support',

        // ====== Page not Found ======
        notfound_bringmeback: 'Take me back',
        notfound_pagenotfound: 'Page not found',
    },
    german: {
        // ====== Embla ====== 
        home_title1: 'Kiny macht Sachen',
        home_text1: 'Softwareentwicklung mit Leidenschaft. Spezialisiert auf Gaming in Unity. Immer auf der Suche nach neuen Projekten und immer am Machen.',
        home_button1: 'Über mich',
        home_title2: 'Spiele für die Welt',
        home_text2: 'Über 6 Jahre Erfahrung mit der Unity Engine. Von 2D, 3D bis hin zu Multiplayer Virtual Reality Projekten ist alles dabei. Egal ob Web oder Standalone.',
        home_button2: 'Projekte',
        home_title3: 'World Wide Web',
        home_text3: 'Über 2 Jahre Erfahrung in der Webentwicklung. Homepages für jedermann. Smart Contracts und Web3 Implementierungen für Künstler und Enthusiasten. Der perfekte Einstieg in die NFT Community.',
        home_button3: 'Kontakt',
        home_things: '#Sachen',
        home_games: '#Spiele',
        home_web: '#Web',

        // ====== Project Details ====== 
        // General
        pjd_status_finished: 'Beendet',
        pjd_status_development: 'In Entwicklung',
        pjd_title_project: 'Das Projekt',
        pjd_title_learning: 'Learning',
        pjd_title_overview: 'Übersicht',
        pjd_title_background: 'Hintergrund',
        pjd_title_recap: 'Rückblick',
        pjd_title_result: 'Ergebnis',

        pjd_backbutton: 'Projekte',
        pjd_platform: 'Plattform:',
        pjd_releaseYear: 'Erscheinungsjahr:',
        pjd_status: 'Status:',
        pjd_duration: 'Dauer:',

        // Igbo
        pjd_igbo_p11: '"Igbo" ist das erste Spiel und Programming Projekt, welches ich je verwirklicht habe. 2017 fing ich an mit mithilfe von Unity das Programmieren beizubringen. 2018 begann ich den Hollow Knight inspirierten Side-Scroller zu entwickeln. Man spielt einen Busch, der in einem Untergrund Dschungel gefangen ist. Ziel ist es dem zu entfliehen.',
        pjd_igbo_p12: 'Das Projekt besteht aus Basismechaniken wie angreifen und springen. Ein Angriff kann durch die Bewegungstasten horizontal oder vertikal ausgeführt werden. Die Spieler*innen können in eine beliebige Richtung dashen und kleine Wandsprünge ausführen.',
        pjd_igbo_p21: 'Anfangs ist die Lernkurve immer besonders hoch. Deswegen lag der Fokus bei diesem Projekt so viel wie möglich in jedem Bereich zu lernen. Dazu zählt programmieren zu lernen, Unity kennenzulernen und ein allgemeines Gefühl für die Spieleentwicklung zu bekommen.',
        pjd_igbo_p22: 'Ziel war es das Spiel fertigzustellen, um es hochzuladen. Damit stellte ich mein Durchhaltevermögen auf die Probe, mit dem Hintergedanken herauszufinden, ob ich länger an einem Projekt arbeiten kann. Das stellte sich später als besonders wichtig heraus.',

        // Enba
        pjd_enba_p11: '"Enba" ist ein Side-Scroller im Pixel-Art Stil, in dem man einen glockenartigen Charakter spielt. Ziel ist es seinen kleinen Glocken-Freund zu finden und mit ihm bis an das Ende der kleinen Welt zu gelangen.',
        pjd_enba_p12: 'Auf dem Weg dahin löst man mit seinem kleinen Freund einfache Rätsel und kämpft gegen bösartige Wesen. Die Mechaniken sind eine Verbesserung von dem Vorgänger Projekt "Igbo" und differenzieren sich dadurch, dass man seinen kleinen Freund als Projektil und Teleportpunkt benutzen kann.',
        pjd_enba_p21: 'Mit diesem Projekt wollte ich alle Aspekte vom letzten Projekt miteinbeziehen. Das bedeutet mehr Erfahrung im programmieren sammeln, Unity besser kennenlernen und meinen eigenen Workflow finden.',
        pjd_enba_p22: 'Meine Unity und C# Skills konnte ich um einiges verbessern. Währenddessen konnte ich ein viel besseres Gefühl für das Projekt entwickeln und merkte auch, wie wichtig Dokumentation ist. Unity fühlte sich langsam vertraut an und ich konnte mich besser zurechtfinden.',

        // Voice Visualizer
        pjd_voicev_p11: '"Voice Visualizer" ist ein Tool, welches ich zum Streaming auf Twitch geschrieben habe. Es ist eine Webcam Alternative, die man in Streaming Software wie OBS Studio einfügen kann.',
        pjd_voicev_p12: 'Anstelle der Kamera hat man ein Bild, welches zu der Mikrofoneingabe vibriert. Zusätzlich kann man weitere Bilder einfügen, welche durch einen Tastendruck für einige Sekunden angezeigt werden. Dafür kann man beispielsweise Emotes benutzen, um Gesichtsausdrücke zu simulieren und den Zuschauer*innen Gefühle zu übermitteln.',
        pjd_voicev_p21: 'Als ich anfing auf Twitch zu streamen, suchte ich nach einer Möglichkeit mein Gesicht nicht zu zeigen, da mir meine Privatsphäre wichtig ist aber den Zuschauer*innen dennoch etwas Visuelles für das Audio zu bieten. So entstand das Konzept ein Bild durch den Klang der Stimme vibrieren zu lassen. Ich entwickelte den Prototyp und mein Problem war gelöst.',
        pjd_voicev_p22: 'Später kam ich auf die Idee das Tool zu erweitern und ein UI dafür zu entwickeln, damit andere Personen es ebenfalls benutzen können. Ich wollte mein Tool mit der Welt teilen und beschloss, das Programm auf Steam zu veröffentlichen.',
        pjd_voicev_p31: 'Zum ersten Mal fand ich mich in einem richtigen Projektkreislauf wieder. Die Software musste so nutzerfreundlich wie möglich sein. Außerdem ist eine gute Dokumentation vonnöten, damit der Einstieg für die Benutzer keine totale Katastrophe wird.',
        pjd_voicev_p32: 'Das stellte sich als große Herausforderung heraus. Für den damaligen Standpunkt habe ich das Beste rausgeholt. Das Projekt öffnete mir die Augen für Details. Die Arbeit mit Steam und externen Tester*innen habe ich sehr genossen und jegliches Feedback verinnerlicht.',

        // Arkanoid
        pjd_arkanoid_p11: 'Vielen ist der Spielklassiker "Arkanoid" ein Begriff. Für mein Studium musste ich die Aufgabe erfüllen, einen "Arkanoid" Klon zu entwickeln.',
        pjd_arkanoid_p12: 'Es gab feste Vorgaben, welche Eigenschaften das Spiel besitzen soll. Dazu zählen: einige Power-ups, einen Score, der steigt, wenn Blöcke zerstört werden, Leben, die abgezogen werden, wenn man den Ball verliert.',
        pjd_arkanoid_p21: 'Im Gegensatz zu den anderen Projekten war dieses ziemlich schnell fertig, da ich schon einiges an Erfahrung in Unity sammeln und meine Programmierkenntnisse zu dem Zeitpunkt sehr verbessern konnte. Es war sehr hilfreich zu wissen, was genau in das Spiel eingebaut werden soll.',
        pjd_arkanoid_p22: 'Dies hat mir gezeigt wie wichtig es ist ein Konzept zu besitzen und Projekte zu planen. Selbst wenn sich während der Entwicklung etwas ändert, sollte man dies festhalten.',

        // Tiny Dino Runner
        pjd_tinydino_p11: 'Viele kennen den Chrome Runner. Der "Tiny Dino Runner" ist ein Web3 basiertes Spiel, in dem man einen winzigen Dino spielt. Dieser Dino rennt schneller als Forrest Gump und weicht gefährlichen Kakteen und Flugsauriern aus.',
        pjd_tinydino_p12: 'Spieler*innen können neue Highscores erreichen und diese mit anderen Spieler*innen auf einem Leaderboard vergleichen. Verifiziert wird man über sein Kryptowallet.',
        pjd_tinydino_p21: '"Tiny Dinos" ist eine NFT-Kollektion mit einer wunderbaren Community. Innerhalb der Community wurden Witze gerissen, dass der Tiny Dino den Chrome Dino ersetzt. Das hatte zur Folge, dass ich die Initiative ergriffen habe und angefangen habe den Witz zur Realität zu machen.',
        pjd_tinydino_p22: 'Da es eine Web3 Community ist, musste ich natürlich dieser auch gerecht werden. Also musste ich mir beibringen, wie man Zugriff auf Kryptowallets und Smart Contracts innerhalb des Spiels bekommt. Dazu kam ein Leaderboard, welche die DAO benutzt hat, um Gewinnspiele zu veranstalten.',
        pjd_tinydino_p31: 'Mittlerweile ist die Verifizierung per Kryptowallet und das Leaderboard abgeschaltet. Da der Verkehr deutlich gesunken ist, lohnt es, sich nicht mehr, die Server online zu halten. Man kann den, "Tiny Dino Runner" dennoch weiterhin spielen. Allerdings ist ein Update bereits in Planung.',
        pjd_tinydino_p32: 'Dieses Projekt ist bis heute eins meiner Lieblinge. Der technische Aufwand war nicht der Rede wert. Die Kommunikation und das tolle Feedback der Community haben das Projekt so besonders gemacht, weswegen es immer etwas Besonderes für mich bleiben wird.',

        // Procedural Creatures/Animations
        pjd_proceduralcreatures_p11: 'In "Procedural Creatures" kann man animierte Wesen durch unebenes Terrain steuern. Die Anzahl der Beine kann verändert werden. Diese Wesen können eigenständig in Unity erstellt werden, man kann aber auch bereits vorhandene Modelle hochladen.',
        pjd_proceduralcreatures_p12: 'Der große Unterschied zu herkömmlichen Animationen ist, dass diese nicht durch Keyframes fest definiert sind. Die Animationen in diesem Programm werden in Echtzeit berechnet und passen das Verhalten dementsprechend an.',
        pjd_proceduralcreatures_p21: 'Zum Semesterende durften wir im Rahmen der Vorlesung "Games Engines" ein Thema aussuchen und dieses bearbeiten. Trotz bekannter Herauforderungen beschloss ich mich an das Thema heranzuwagen.',
        pjd_proceduralcreatures_p22: 'Das Ziel war es prozedurale Animationen vorzuführen. Allerdings wollte ich das Skript wiederverwendbar machen, eine unterschiedliche Anzahl an Beinen unterstützen und importierte Modelle ebenfalls unterstützen.',
        pjd_proceduralcreatures_p31: 'Ohne das richtige Konzept wäre dieses Projekt nicht möglich gewesen. Durch eine gute Recherche und einen festen Plan konnte ich den Workflow so optimieren, dass ich gegen Ende hin genug Zeit hatte ein Level zu bauen und Bewegungen zu optimieren.',
        pjd_proceduralcreatures_p32: 'Es hat sich wieder einmal herausgestellt, dass die richtige Planung alles möglich macht. Zudem hat das Projekt ein halbes Jahr später an Aufmerksamkeit gewonnen. Es war Bestandteil von Videoaufnahmen der Hochschule und bekam äußerst positives Feedback.',

        // Stormrush
        pjd_stormrush_p11: 'Project: Storm Rush ist ein Virtual Realtiy Multiplayer Spiel, in dem Spieler*innen ihre Stadt vor Monster beschützen müssen. Es ist ein Wavedefense Spiel, indem Wellen von Gegnern die Mana-Pflanzen der Stadt zerstören. Die Spieler*innen müssen diese daran hindern und bekommen dadurch neue Waffen zum Kämpfen.',
        pjd_stormrush_p12: 'An einem Wunschbrunnen können die Spieler*innen erhaltene Münzen gegen zufällige Waffen eintauschen. Zusätzlich kann man Waffen miteinander verschmelzen, um stärkere Waffen herzustellen. Sterben die Spieler*innen so müssen sie zum Friedhof laufen, um sich wiederbeleben zu können.',
        pjd_stormrush_p21: 'Dieses Projekt entstand im Rahmen der Veranstaltung "Labor Games" und soll ein großes Thema beinhalten. In diesem Fall ist es Virtual Reality und Networking. Mein Studienpartner und ich wollten unbedingt ein VR Projekt umsetzen, allerdings war das zu wenig und das Networking kam dazu.',
        pjd_stormrush_p22: 'Die Herausforderungen waren somit auch Virtual Reality und Networking. Zum damaligen Zeitpunkt hatte niemand aus unserem zweier Team Erfahrung in diesem Gebiet. Die Messlatte war hoch und wir wussten nicht, ob wir diese zwei großen Themen bewältigen können.',
        pjd_stormrush_p31: 'Nachdem wir uns die VR und Networking Basics beigebracht haben, stellte sich heraus, dass die eigentliche Herausforderung das Networking war. Zudem mussten wir Motion Sickness berücksichtigen. Wir haben Einstellungen für unterschiedliche Bewegungsarten, um das Spielerlebnis zu vereinfachen erstellt. Haptisches Feedback war ebenfalls vonnöten, um die Erfahrung so immersiv wie möglich zu gestalten.',
        pjd_stormrush_p32: 'Durch Abstraktion haben wir uns viel Arbeit erspart. Es war eine spaßige Erfahrung, die Entwicklung auf Twitch zu streamen und Freunde miteinzubeziehen, die Modelle für das Spiel erstellt haben. Zudem war das Feedback von allen Seiten stets positiv, was uns motiviert hat, immer weiterzuarbeiten.',

        // Procedural Level
        pjd_proceduralgeneration_p11: 'Dieses Projekt widmet sich der Entwicklung eines Tools zur prozeduralen Generierung von 2D-Leveln, darunter Landschaften und Dungeons, die miteinander verknüpft werden können. Diese Level und Levelabfolgen können mithilfe von Seeds neu generiert oder rekonfiguriert werden.',
        pjd_proceduralgeneration_p12: 'Innerhalb dieser Level können zusätzliche Objekte wie Häuser, Bäume usw. erstellt werden, um den Levels mehr Leben einzuhauchen. Es war von grundlegender Bedeutung, dem Tool eine maximale Flexibilität für Entwickler*innen zu ermöglichen. All dies wurde im Rahmen meiner Bachelorarbeit entwickelt.',
        pjd_proceduralgeneration_p21: 'Die Entwicklung des Tools war von Herausforderungen geprägt. Einerseits erforderte sie die Auseinandersetzung mit dem komplexen Gebiet der Algorithmen, meinem "Erzfeind". Andererseits erforderte sie die Erkundung neuer Wege zur Erzeugung bestimmter Muster und deren Integration in ein zentrales System.',
        pjd_proceduralgeneration_p22: 'Monate später ernte ich die Früchte meiner guten Dokumentation. Das Tool wird nun erfolgreich in einem neuen Projekt eingesetzt und ausschließlich für die Generierung von Landschaften weiterentwickelt.',

        // Footgun
        pjd_footgun_p11: 'Footgun ist ein Action-Roguelike, in dem Spielerinnen und Spieler sich durch einzigartige Level bewegen und sich ihren Weg zur nächsten Station erkämpfen, indem sie Monster mit ihrem Fußball besiegen.',
        pjd_footgun_p12: 'Dabei können verschiedene Bälle mit verschiedenen Arten von Power-Ups kombiniert werden, wodurch die Spielerinnen und Spieler ihren eigenen individuellen Spielstil entwickeln können.',
        pjd_footgun_p21: 'Im Jahr 2023 habe ich mich dem Indie-Studio Turtle Knight Games angeschlossen, welches an der Entwicklung des Spiels "Footgun Underground" beteiligt ist. Dies ist das erste größere Team, in dem ich an einem Spiel arbeite.',

        // ====== Navbar ======
        menu_home: 'startseite',
        menu_projects: 'projekte',
        menu_about: 'über mich',
        menu_contact: 'kontakt',
        menu_language: 'sprache',

        // ====== About ======
        about_pagetitle: 'Über mich',
        about_title1: 'Ich bin Kiny',
        about_text1: 'ein 24 Jahre alter Software-Engineering Student aus Deutschland. Seit über 6 Jahren beschäftige ich mich mit dem Programmieren verschiedenster Software. Ich bin nach einer zweijährigen schulischen Ausbildung im Designbereich in die Informatik gerutscht.',
        about_title2: 'Meine Leidenschaft',
        about_text2: 'ist es Spiele zu programmieren und meiner Kreativität freien Lauf zu lassen. Schon als kleiner Junge war ich begeistert von Videospielen und habe mir vorgestellt, wie es wohl sein mag ein eigenes Spiel zu kreieren. Leider hat mich das Programmieren immer davon abgehalten, bis ich eines Tages den ersten Schritt getan habe und einfach damit angefangen habe. Mittlerweile habe ich schon über 6 Jahre Erfahrung und kenne Unity so gut wie meine Westentasche. Der Reiz neues zu lernen und auszuprobieren lässt mich an dieser Leidenschaft festhalten. Die Vielseitigkeit im Game Development ist begeisternd!',
        about_title3: 'Webseiten',
        about_text3: 'habe ich bereits in meiner schulischen Ausbildung erstellt. Durch ein Praktikum fand ich gefallen an der Webentwicklung und fing an React und Tailwind zu lernen. Seitdem habe ich Webseiten für NFT Projekte erstellt und einige kleine Projekte, die nie das Licht der Welt zu sehen bekommen, erstellt. Die Arbeit am Frontend genieße ich am meisten, da ich meiner Kreativität freien Lauf lassen kann.',
        about_title4: 'Web3',
        about_text4: 'ist ein neues Gebiet, in das ich eingetaucht bin. Die Möglichkeit, Scripts auf der Blockchain laufen zu lassen, reizte mich sehr. In Solidity habe ich Smart Contracts für NFT Kollektionen geschrieben und diese mit Spielen oder Webseiten verbunden. Ein Spiel war der "Tiny Dino Runner" für eine kleine, aber feine Community.',
        about_title5: 'Python',
        about_text5: 'ist nicht mein Haustier, sondern die Programmiersprache, die ich für verschiedene Bots benutzt habe. Von Bots für Videospiele bis hin zu Web3 und AI basierten Chatbots war alles dabei. Zudem nutze ich Pyhton gerne auch für Web Scraping oder Automatisierungen, um Zeit zu sparen.',
        about_title6: 'Train like you fight',
        about_text6: 'ist das Sprichwort, welches ich immer im Hinterkopf behalte. Sich ständig zu verbessern und neues zu lernen, ist mein Lebensstil. Jeder macht Fehler, doch die Kunst dahinter ist diese einzugestehen und sie das nächste Mal zu vermeiden. Wenn Kommunikation der Schlüssel ist, ist Realismus die Hand, welche die Tür öffnet.',
        about_title_skilltree: 'Fähigkeitsbaum',
        about_programming_languages: 'Programmiersprachen',
        about_available_skillpoints: 'Verfügbare Fähigkeitspunkte',
        about_used_skillpoints: 'Benutzte Fähigkeitspunkte',

        // ====== Contact ======
        contact: 'Kontakt',

        button_error: 'OK',
        button_success: 'Bring mich zurück',

        // Beispielaufträge
        blist_openbutton: 'Aufträge',
        blist_description: 'Mögliche Aufträge findest du hier. Falls deine Anfrage nicht dabei ist, kontaktiere mich einfach. Fragen kostet nichts. Preise für Aufträge variieren je nach Umfang.',
        blist_unity_1: '2D Spiel',
        blist_unity_2: '3D Spiel',
        blist_unity_3: 'Multiplayer Spiel',
        blist_unity_4: 'Erweiterungen für bestehende Spiele',
        blist_unity_5: 'Web3 Implementierung',

        blist_web2_1: 'Website Erstellung',
        blist_web2_2: 'Website Bearbeitung',
        blist_web2_3: 'Website Erweiterung',
        blist_web2_4: 'Hosting Support',

        blist_web3_1: 'Smart Contract Erstellung',
        blist_web3_2: 'Implementierung für Webseiten',
        blist_web3_3: 'Implementierung in Unity Games',
        blist_web3_4: 'NFT Generierung mit Metadata',
        blist_web3_5: 'IPFS Support',

        // ====== Page not Found ======
        notfound_bringmeback: 'Bring mich zurück!',
        notfound_pagenotfound: 'Seite nicht gefunden',
    }
}

export { content };