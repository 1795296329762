import React from 'react';

const RoundLinkButton = ({ text, link, icon, isDark = false }) => {
    return (
        <a href={link} target='_blank' rel='noreferrer'
            className={`px-4 py-2 w-fit rounded-full ${isDark ? 'text-primary bg-primary' : 'text-white bg-white'}
            hover:bg-opacity-30 bg-opacity-10 duration-200 col-span-2`}>
            <div className='flex flex-row items-center gap-2'>

                {/* <span className='text-xs lg:text-sm font-mono text-left'>
                    {text}
                </span> */}

                <div className='fill-current duration-700'>
                    {icon}
                </div>

            </div>
        </a>
    )
}

export default RoundLinkButton