import React from 'react';
import useDynamicThemeColor from '../hooks/useDynamicThemeColor';
import SocialsCard from './SocialsCard';
import { SiTwitter, SiInstagram, SiTwitch, SiDiscord, SiYoutube, SiTiktok } from 'react-icons/si';
import { FaHome } from 'react-icons/fa';
import QRTwitter from '../media/socials/qrtwitter.png';
import QRInstagram from '../media/socials/qrinstagram.png';
import QRTwitch from '../media/socials/qrtwitch.png';
import QRDiscord from '../media/socials/qrdiscord.png';
import QRYoutube from '../media/socials/qryoutube.png';
import QRTiktok from '../media/socials/qrtiktok.png';
import QRWebsite from '../media/socials/qrwebsite.png';
import RoundButton from './RoundButton';
import { gsap } from 'gsap';

const Socials = ({ setIsPrimaryColorNavbar }) => {

    useDynamicThemeColor(false);
    setIsPrimaryColorNavbar(false);

    const [isQR, setIsQR] = React.useState(false);
    const qrRef = React.useRef();
    const [qRImage, setQRImage] = React.useState();

    React.useEffect(() => {
        if (qrRef == null)
            return;

        if (isQR) {
            gsap.to(qrRef.current, { duration: .1, opacity: 1 });
        }
        else {
            gsap.to(qrRef.current, { duration: .1, opacity: 0 });
        }

    }, [isQR]);

    function showQRCode(socialTag) {

        if (socialTag === 'Twitter')
            setQRImage(QRTwitter);
        else if (socialTag === 'Instagram')
            setQRImage(QRInstagram);
        else if (socialTag === 'Twitch')
            setQRImage(QRTwitch);
        else if (socialTag === 'Discord')
            setQRImage(QRDiscord);
        else if (socialTag === 'Youtube')
            setQRImage(QRYoutube);
        else if (socialTag === 'Tiktok')
            setQRImage(QRTiktok);
        else if (socialTag === 'Homepage')
            setQRImage(QRWebsite);
        else
            setQRImage('');

        setIsQR(true);
    }

    return (
        <div className='bg-secondary w-screen h-screen flex flex-col pt-20 pb-28 px-6 
            lg:pt-32 lg:px-8 overflow-y-scroll items-center'>
            <div className='flex flex-col gap-2 w-full max-w-md'>
                <SocialsCard text={"Twitter"} icon={<SiTwitter size={24} />} link={'https://twitter.com/0xKiny'} onClickQR={showQRCode} />
                <SocialsCard text={"Instagram"} icon={<SiInstagram size={24} />} link={'https://www.instagram.com/kinychanchan/'} onClickQR={showQRCode} />
                <SocialsCard text={"Twitch"} icon={<SiTwitch size={24} />} link={'https://www.twitch.tv/kinychanchan'} onClickQR={showQRCode} />
                <SocialsCard text={"Discord"} icon={<SiDiscord size={24} />} link={'https://discord.gg/wvnS5fHWKT'} onClickQR={showQRCode} />
                <SocialsCard text={"Youtube"} icon={<SiYoutube size={24} />} link={'https://www.youtube.com/channel/UCaX4pnJiVCkjn6xV9K_iHww'} onClickQR={showQRCode} />
                <SocialsCard text={"Tiktok"} icon={<SiTiktok size={24} />} link={'https://www.tiktok.com/@kinychanchan'} onClickQR={showQRCode} />
                <SocialsCard text={"Homepage"} icon={<FaHome size={24} />} link={'https://www.kinychanchan.com'} onClickQR={showQRCode} />
            </div>

            <div ref={qrRef} className={`${isQR ? 'flex' : 'hidden'} fixed left-5 right-5 top-20 bg-black backdrop-blur-md opacity-0
                flex-col items-center duration-700 text-white px-4 py-4 text-left overflow-hidden max-w-md mx-auto rounded-md z-10`}>
                <img src={qRImage} alt='QR Code'
                    className='w-full'
                />

                <div className='pt-4'>
                    <RoundButton text={'OK'} onClick={() => setIsQR(false)} isLeft={true}/>
                </div>
            </div>
        </div>
    )
}

export default Socials