import React from 'react';
import NormalText from '../text/NormalText';
import { gsap } from 'gsap';
import { useInView } from 'react-intersection-observer';

const SkillBar = ({text, percent}) => {

    const  barRef = React.useRef(null);
    const {ref: ref, inView: barVisible} = useInView();
    const [wasVisible, setWasVisible] = React.useState(false);

    React.useEffect(() => {
        if(!wasVisible && barVisible)
        {
            gsap.to(barRef.current, {duration: 3, width: percent});
            setWasVisible(true);
        }
    }, [barVisible])

    return (
        <div ref={ref} className='w-full h-10 grid grid-cols-2 items-center'>
            <div className='h3'>
                <NormalText text={text} />
            </div>
            <div className="bg-primary-2 rounded-full h-3 mt-2">
                <div ref={barRef} className="bg-secondary h-3 rounded-full w-0"></div>
            </div>
        </div>
    )
}

export default SkillBar