import React from 'react';
import useDynamicThemeColor from '../hooks/useDynamicThemeColor';
import MainHeader from '../text/MainHeader';
import RoundButton from './RoundButton';
import ErrorImage from '../media/404.png';
import { content } from './content'; // Übersetzungen

const ProjectPage = ({ setIsPrimaryColorNavbar, language }) => {

  useDynamicThemeColor(false);
  setIsPrimaryColorNavbar(true);

  let currentContent = {};
  language === 'english' ? (currentContent = content.english) : (currentContent = content.german);

  return (
    <div className='w-full flex flex-col items-center min-h-screen pt-20 px-6 
    lg:pt-32 lg:px-8 scroll-smooth bg-secondary gap-10 md:gap-5 duration-700'>

      <div className='text-white w-full h-full flex flex-col items-center justify-center'>

        <img className='w-1/4 max-w-[200px]' src={ErrorImage} alt=''/>

        <div className='pt-5'>
          <MainHeader text={currentContent.notfound_pagenotfound} />
        </div>

        <div className='pt-5'>
          <RoundButton link={'/'} text={currentContent.notfound_bringmeback} isLeft={true} />
        </div>

      </div>

    </div>
  )
}

export default ProjectPage