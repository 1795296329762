import React from 'react'

const PageHeader = ({ text, additionalStyle }) => {
    return (
        <h2 className={`font-800 top-0 font-sans text-4xl lg:text-6xl uppercase pb-4 font-black tracking-tight duration-700 ${additionalStyle}`}>
            {text}
        </h2>
    )
}

export default PageHeader