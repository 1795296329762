import React from 'react';
import EmblaSlideDesktop from './EmblaSlideDesktop';
import { content } from './content'; // Übersetzungen

// import { Animator, ScrollContainer, ScrollPage, Fade, MoveOut, batch, Sticky, Move } from 'react-scroll-motion';
// import LastSlide from './LastSlide';

const EmblaDesktop = ({ slideRef1, slideRef2, slideRef3, language }) => {

    // const FadeOutDesktopAnimation = batch(Fade(), MoveOut(0, 500));

    // Content übersetzt in allen verfügbaren Sprachen
    let currentContent = {};
    language === 'english' ? (currentContent = content.english) : (currentContent = content.german);

    return (
        <div className='w-screen h-screen overflow-y-scroll snap-y snap-mandatory'>
            <EmblaSlideDesktop
                title={currentContent.home_title1}
                text={currentContent.home_text1}
                buttonText={currentContent.home_button1}
                slideRef={slideRef1}
                link={'/about'}
            />

            <EmblaSlideDesktop
                title={currentContent.home_title2}
                text={currentContent.home_text2}
                buttonText={currentContent.home_button2}
                slideRef={slideRef2}
                link={'/projects'}
            />

            <EmblaSlideDesktop
                title={currentContent.home_title3}
                text={currentContent.home_text3}
                buttonText={currentContent.home_button3}
                slideRef={slideRef3}
                link={'/contact'}
            />
        </div>
        // <ScrollContainer snap='proximity'>

        //     <ScrollPage page={0}>
        //         <Animator animation={FadeOutDesktopAnimation}>
        //             <EmblaSlideDesktop
        //                 title={content.home_title1}
        //                 text={content.home_text1}
        //                 buttonText={content.home_button1}
        //                 slideRef={slideRef1}
        //                 link={'/about'}
        //             />
        //         </Animator>
        //     </ScrollPage>

        //     <ScrollPage page={1}>
        //         <Animator animation={FadeOutDesktopAnimation}>
        //             <EmblaSlideDesktop
        //                 title={content.home_title2}
        //                 text={content.home_text2}
        //                 buttonText={content.home_button2}
        //                 slideRef={slideRef2}
        //                 link={'/projects'}
        //             />
        //         </Animator>
        //     </ScrollPage>

        //     <ScrollPage page={2}>
        //         <Animator animation={FadeOutDesktopAnimation}>
        //             <EmblaSlideDesktop
        //                 title={content.home_title3}
        //                 text={content.home_text3}
        //                 buttonText={content.home_button3}
        //                 slideRef={slideRef3}
        //                 link={'/contact'}
        //             />
        //         </Animator>
        //     </ScrollPage>

        // </ScrollContainer>
    )
}

export default EmblaDesktop