import React from 'react';
import { Link } from "react-router-dom";

const NavButtonMobile = ({ text, link, icon = null, onClick, href = null }) => {
    return (
        <li>
            {
                href === null ?
                    <Link to={link} onClick={onClick} className='uppercase py-3 w-full flex items-center text-whitesmoke border-b border-whitesmoke border-opacity-10'>
                        {text}

                        <div className='h-5 w-5 ml-auto fill-current'>
                            {icon !== null && icon}
                        </div>

                    </Link>
                    :
                    <a href={href} target='_blank' className='uppercase py-3 w-full flex items-center text-whitesmoke border-b border-whitesmoke border-opacity-10'>
                        {text}

                        <div className='h-5 w-5 ml-auto fill-current'>
                            {icon !== null && icon}
                        </div>

                    </a>
            }
        </li>
    )
}

export default NavButtonMobile