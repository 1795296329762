import React from 'react';
import HomeHeader from '../text/HomeHeader';
import NormalText from '../text/NormalText';
import RoundButton from './RoundButton';

const EmblaSlide = ({ title, text, buttonText, link }) => {
    return (
        <div className="embla__slide slide_full px-8 pt-28">
            <div className='text-left md:max-w-xl'>
                <HomeHeader text={title} />
                <NormalText text={text} additionalStyle={'text-white'}/>
            </div>

            <div className='w-full flex mt-5'>
                <RoundButton text={buttonText} link={link} />
            </div>
        </div>
    )
}

export default EmblaSlide