import React from 'react';

import SmallText from '../text/SmallText';
import PageHeader from '../text/PageHeader';
import SubHeader from '../text/SubHeader';
import AboutCard from './AboutCard';
import SkillBar from './SkillBar';
import AboutImage from '../media/700x1400-web-1.png';
import KinyLaugh from '../media/kinylaugh.png';
import KinyPoster from '../media/render.jpg';
import KinyRender from '../media/kinywireframe.mp4';

import useDynamicThemeColor from '../hooks/useDynamicThemeColor';

import { content } from './content'; // Übersetzungen

const About = ({ language, setIsPrimaryColorNavbar }) => {

    useDynamicThemeColor(true);
    setIsPrimaryColorNavbar(false);

    // Content übersetzt in allen verfügbaren Sprachen
    let currentContent = {}
    language === 'english' ? (currentContent = content.english) : (currentContent = content.german);

    return (
        <div className='bg-primary w-screen h-screen flex flex-col pt-20 pb-28 px-6 
        lg:pt-32 lg:px-8 text-left overflow-y-scroll'>

            <div className='w-full flex flex-row justify-center'>
                <div className='w-full max-w-7xl'>
                    <PageHeader text={currentContent.about_pagetitle} />
                </div>
            </div>

            {/* Zentriert */}
            <div className='w-full flex flex-row justify-center pt-5'>

                <div className='flex flex-row justify-center max-w-7xl'>
                    <div className='grid grid-cols-1 gap-2 md:grid-cols-4 duration-100'>
                        <AboutCard
                            title={"Hi!"}
                            gridstyle={'md:item1 md:col-span-0 md:row-span-1 bg-secondary'}
                        />
                        <AboutCard
                            title={currentContent.about_title1}
                            text={currentContent.about_text1}
                            gridstyle={'md:item2 md:col-span-1 md:row-span-2 bg-primary-2'}
                        />

                        <video
                            className='w-full md:item8 md:col-span-1 md:row-span-1'
                            src={KinyRender}
                            poster={KinyPoster}
                            type="video/mp4"
                            autoPlay
                            muted
                            loop
                            // wbkit-playsInline
                            wbkit-playsinline="true"
                            playsInline
                            preload='auto'
                        />

                        <img src={KinyLaugh} className='w-full bg-secondary md:item8 md:col-span-1 md:row-span-1' />

                        <AboutCard
                            title={currentContent.about_title2}
                            text={currentContent.about_text2}
                            gridstyle={'md:item3 md:col-span-2 md:row-span-2 bg-primary-2'}
                        />
                        <AboutCard
                            title={currentContent.about_title3}
                            text={currentContent.about_text3}
                            gridstyle={'md:item4 md:col-span-2 md:row-span-2 bg-primary-2'}
                        />
                        <AboutCard
                            title={currentContent.about_title4}
                            text={currentContent.about_text4}
                            gridstyle={'md:item5 md:col-span-2 md:row-span-1 lg:col-span-1 lg:row-span-2 bg-primary-2'}
                        />
                        <AboutCard
                            title={currentContent.about_title5}
                            text={currentContent.about_text5}
                            gridstyle={'md:item6 md:col-span-2 md:row-span-1 lg:col-span-1 lg:row-span-2 bg-primary-2'}
                        />

                        <div className='w-full md:item7 md:col-span-2 md:row-span-2 lg:col-span-1 lg:row-span-2 md:flex md:flex-row-reverse'>
                            <img src={AboutImage} className='w-full h-auto bg-secondary' />
                        </div>

                        <AboutCard
                            title={currentContent.about_title6}
                            text={currentContent.about_text6}
                            gridstyle={'md:item8 md:col-span-2 md:row-span-1 lg:col-span-1 lg:row-span-2 bg-primary-2'}
                        />
                    </div>
                </div>

            </div>

            <div className='w-full flex flex-row justify-center pt-28'>
                <div className='w-full max-w-7xl'>
                    <PageHeader text={currentContent.about_title_skilltree} />
                </div>
            </div>

            <div className='sm:w-full sm:flex sm:flex-row sm:justify-center'>
                <div className='sm:flex gap-5 lg:gap-10 w-full flex-row justify-center max-w-7xl duration-700 pt-5'>
                    <div className='w-full'>
                        <SubHeader text={currentContent.about_programming_languages} />
                        <SkillBar percent={'100%'} text={'C#'} />
                        <SkillBar percent={'75%'} text={'Java'} />
                        <SkillBar percent={'70%'} text={'Python'} />
                        <SkillBar percent={'85%'} text={'HTML'} />
                        <SkillBar percent={'85%'} text={'CSS'} />
                        <SkillBar percent={'75%'} text={'Javascript'} />
                        <SkillBar percent={'50%'} text={'Solidity'} />
                    </div>

                    <div className='w-full mt-5 sm:mt-0'>
                        <SubHeader text={"Software"} />
                        <SkillBar percent={'100%'} text={'Unity'} />
                        <SkillBar percent={'40%'} text={'Blender'} />
                        <SkillBar percent={'70%'} text={'Aseprite'} />
                        <SkillBar percent={'65%'} text={'Photoshop'} />
                        <SkillBar percent={'60%'} text={'InDesign'} />
                        <SkillBar percent={'50%'} text={'Premiere'} />
                    </div>

                </div>

            </div>

            <div className='sm:w-full sm:flex sm:flex-row sm:justify-center'>
                <div className='sm:flex gap-5 lg:gap-10 w-full flex-row justify-center max-w-7xl duration-700 pt-5'>
                    <SmallText text={`${currentContent.about_available_skillpoints}: 75`} />
                    <SmallText text={`${currentContent.about_used_skillpoints}: 25`} />
                </div>
            </div>

        </div>
    )
}

export default About