import React from 'react'
import { HiOutlineArrowNarrowRight, HiOutlineArrowNarrowLeft } from 'react-icons/hi'
import { Link } from 'react-router-dom'

const RoundButton = ({text = null, link, isLeft = false, onClick = null, isDark = false}) => {
    return (
        <div onClick={onClick} className={`px-4 py-2 w-fit rounded-full ${isDark ? 'text-primary bg-primary' : 'text-white bg-white'} hover:bg-opacity-30 bg-opacity-10 duration-200`}>
            <Link to={link}>

                <div className={`flex ${isLeft ? 'flex-row-reverse' : 'flex-row'} items-center ${text != null ? 'gap-2' : ''}`}>

                    <span className='text-xs lg:text-sm font-mono text-left'>
                        {text}
                    </span>

                    <div className='fill-current duration-700'>
                    {
                        isLeft ?
                        <HiOutlineArrowNarrowLeft size={24} />
                        :
                        <HiOutlineArrowNarrowRight size={24} />
                    }
                    </div>

                </div>

            </Link>
        </div>
    )
}

export default RoundButton