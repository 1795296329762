import React from 'react'

const HomeHeader = ({ text, additionalStyle }) => {
    return (
        <h1 className={`text-5xl font-extrabold uppercase ${additionalStyle}`}>
            {text}
        </h1>
    )
}

export default HomeHeader