import React from 'react';
import { gsap } from 'gsap';

const NavLanguageDropdown = ({ text, onLanguageChange, icon, isPrimary }) => {

    const [isOpen, setIsOpen] = React.useState(false);
    const menuRef = React.useRef(null);

    React.useEffect(() => {
        if (isOpen) {
            menuRef.current.classList.remove('hidden');
            gsap.to(menuRef.current, { duration: .2, opacity: '100%' });
        }
        else {
            gsap.to(menuRef.current, { duration: .2, opacity: '0%' });
            menuRef.current.classList.add('hidden');
        }
    }, [isOpen])

    return (
        <li>
            {/* Mobile/Tablet */}
            <div className='lg:hidden'>
                <div onClick={() => setIsOpen(!isOpen)} className='uppercase py-3 w-full flex items-center text-whitesmoke border-b border-whitesmoke border-opacity-10 cursor-pointer'>
                    {text}

                    <div className='h-5 w-5 ml-auto fill-current'>
                        {icon}
                    </div>
                </div>

                {
                    isOpen &&
                    <div>
                        <div onClick={() => { setIsOpen(false); onLanguageChange('english') }} className='uppercase pl-2 py-3 w-full flex items-center text-whitesmoke cursor-pointer'>
                            English
                        </div>
                        <div onClick={() => { setIsOpen(false); onLanguageChange('german') }} className='uppercase pl-2 py-3 w-full flex items-center text-whitesmoke cursor-pointer'>
                            Deutsch
                        </div>
                    </div>
                }
            </div>

            {/* Desktop */}
            <div className='hidden lg:block'>
                <button onClick={() => setIsOpen(!isOpen)} className={`${isPrimary ? 'text-primary bg-primary' : 'text-white bg-white'} hover:bg-opacity-30 bg-opacity-10 items-center relative h-7 font-mono tracking-wider pt-0.5 first::pt-0 uppercase text-2xs font-500 padding-huge duration-200 px-4 rounded flex justify-center flex-row cursor-pointer`}>
                    {icon}
                </button>

                <div ref={menuRef} className={`hidden absolute opacity-0 mt-2 ${isPrimary ? 'bg-primary text-primary' : 'bg-white text-white'} bg-opacity-10 text-left py-2 rounded`}>
                    <div onClick={() => { setIsOpen(false); onLanguageChange('english') }} 
                        className={`px-2 ${isPrimary ? 'bg-primary' : 'bg-white'} bg-opacity-0 hover:bg-opacity-30 h-7 font-mono tracking-wider pt-0.5 first::pt-0 uppercase text-2xs font-500 padding-huge duration-200 cursor-pointer`}>
                        English
                    </div>
                    <div onClick={() => { setIsOpen(false); onLanguageChange('german') }} 
                        className={`px-2 ${isPrimary ? 'bg-primary' : 'bg-white'} bg-opacity-0 hover:bg-opacity-30 h-7 font-mono tracking-wider pt-0.5 first::pt-0 uppercase text-2xs font-500 padding-huge duration-200 cursor-pointer`}>
                        Deutsch
                    </div>
                </div>

            </div>
        </li>
    )
}

export default NavLanguageDropdown