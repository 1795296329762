import React from 'react';

const EmblaButton = ({ text, isSelected, onClick }) => {
    return (
        <button onClick={onClick}
            className={`w-full border-white text-white border-b-2 text-left
            lg:border-b-0 lg:border-l-4 lg:h-20 lg:w-auto lg:border-black
            ${isSelected ? 'opacity-70' : 'opacity-10'} lg:cursor-default`}
        >

            <a className='lg:hidden'>{text}</a>
        </button>
    )
}

export default EmblaButton