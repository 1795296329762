import React from 'react';
import './App.css';
import './index.css';
import Embla from './components/Embla.jsx';
import Projects from './components/Projects';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Contact from './components/Contact';
import PageNotFound from './components/PageNotFound';
import ProjectDetails from './components/ProjectDetails';
import About from './components/About';
import Socials from './components/Socials';
import { content } from './components/content'; // Übersetzungen

import Igbo2 from './media/igbo/2.jpg';
import Igbo4 from './media/igbo/4.jpg';

import Enba2 from './media/enba/2.jpg';
import Enba3 from './media/enba/3.jpg';

import VoiceVisualizer1 from './media/voicevisualizer/1.jpg';
import VoiceVisualizer2 from './media/voicevisualizer/2.jpg';
import VoiceVisualizer3 from './media/voicevisualizer/3.jpg';

import Arkanoid1 from './media/arkanoid/1.jpg';
import Arkanoid2 from './media/arkanoid/2.jpg';

import TinyDinoRunner1 from './media/tinydinorunner/splash.png';
import TinyDinoRunner2 from './media/tinydinorunner/header.jpg';
import TinyDinoRunner3 from './media/tinydinorunner/2.jpg';

import Procedural1 from './media/procedural/1.jpg';
import Procedural2 from './media/procedural/2.jpg';
import Procedural3 from './media/procedural/3.jpg';

import StormRush1 from './media/stormrush/1.jpg';
import StormRush2 from './media/stormrush/2.jpg';
import StormRush3 from './media/stormrush/3.jpg';

import ProceduralGeneration1 from './media/proceduralgeneration/1.jpg';
import ProceduralGeneration2 from './media/proceduralgeneration/2.jpg';

import Footgun1 from './media/footgun/2.jpg';
import Footgun2 from './media/footgun/3.jpg';

function App() {

  // TODO: Language als Hook implementieren und in kleinere Scripts/Vars aufteilen
  let languageStoredInLocalStorage = localStorage.getItem("language");
  const [language, setLanguage] = React.useState(
    languageStoredInLocalStorage ? languageStoredInLocalStorage : 'english'
  );

  function storeLanguageInLocalStorage(language) {
    setLanguage(language);
    localStorage.setItem("language", language);
  }

  // Content übersetzt in allen verfügbaren Sprachen
  let curerntContent = {};
  language === 'english' ? (curerntContent = content.english) : (curerntContent = content.german);

  // Color für Navbar
  const [isPrimaryColorNavbar, setIsPrimaryColorNavbar] = React.useState(true);

  return (
    <div className="App">
      <Router>
        <Navbar
          handleSetLanguage={(language) => storeLanguageInLocalStorage(language)}
          language={language}
          isPrimary={isPrimaryColorNavbar}
        />

        <Routes>
          <Route path='/' element={<Embla language={language} setIsPrimaryColorNavbar={setIsPrimaryColorNavbar} />} />

          <Route path='/projects' element={<Projects setIsPrimaryColorNavbar={setIsPrimaryColorNavbar} />} />
          <Route path='/projects/igbo' element={<ProjectDetails
            language={language}
            projectTitle={'Igbo'}
            releaseYear={'2019'}
            status={curerntContent.pjd_status_finished}
            projectHeroImage={'bg-hero-igbo'}
            title1={curerntContent.pjd_title_project}
            paragaph1_1={curerntContent.pjd_igbo_p11}
            paragaph1_2={curerntContent.pjd_igbo_p12}
            image1={Igbo2}
            title2={curerntContent.pjd_title_learning}
            paragaph2_1={curerntContent.pjd_igbo_p21}
            paragaph2_2={curerntContent.pjd_igbo_p22}
            image2={Igbo4}
            watchVideoLink={'https://youtu.be/UXDwwHg4Y74'}
            itchLink={'https://kiny.itch.io/igbo'}
            duration={'365 d'}
            setIsPrimaryColorNavbar={setIsPrimaryColorNavbar}
            platform={'windows'}
          />} />
          <Route path='/projects/enba' element={<ProjectDetails
            language={language}
            projectTitle={'Enba'}
            releaseYear={'2020'}
            status={curerntContent.pjd_status_finished}
            projectHeroImage={'bg-hero-enba'}
            title1={curerntContent.pjd_title_project}
            paragaph1_1={curerntContent.pjd_enba_p11}
            paragaph1_2={curerntContent.pjd_enba_p12}
            image1={Enba3}
            title2={curerntContent.pjd_title_learning}
            paragaph2_1={curerntContent.pjd_enba_p21}
            paragaph2_2={curerntContent.pjd_enba_p22}
            image2={Enba2}
            watchVideoLink={'https://youtu.be/ryjBredWBgk'}
            itchLink={'https://kiny.itch.io/enba'}
            duration={'365 d'}
            setIsPrimaryColorNavbar={setIsPrimaryColorNavbar}
            platform={'web, windows'}
          />} />
          <Route path='/projects/voicevisualizer' element={<ProjectDetails
            language={language}
            projectTitle={'Voice Visualizer'}
            releaseYear={'2021'}
            status={curerntContent.pjd_status_finished}
            projectHeroImage={'bg-hero-voice-visualizer'}
            title1={curerntContent.pjd_title_project}
            paragaph1_1={curerntContent.pjd_voicev_p11}
            paragaph1_2={curerntContent.pjd_voicev_p12}
            image1={VoiceVisualizer1}
            title2={curerntContent.pjd_title_background}
            paragaph2_1={curerntContent.pjd_voicev_p21}
            paragaph2_2={curerntContent.pjd_voicev_p22}
            image2={VoiceVisualizer2}
            title3={curerntContent.pjd_title_learning}
            paragaph3_1={curerntContent.pjd_voicev_p31}
            paragaph3_2={curerntContent.pjd_voicev_p32}
            image3={VoiceVisualizer3}
            steamLink={'https://store.steampowered.com/app/1735480/Voice_Visualizer/'}
            duration={'213 d'}
            setIsPrimaryColorNavbar={setIsPrimaryColorNavbar}
            platform={'steam, windows'}
          />} />
          <Route path='/projects/arkanoid' element={<ProjectDetails
            language={language}
            projectTitle={'Arkanoid'}
            releaseYear={'2022'}
            status={curerntContent.pjd_status_finished}
            projectHeroImage={'bg-hero-arkanoid'}
            title1={curerntContent.pjd_title_overview}
            paragaph1_1={curerntContent.pjd_arkanoid_p11}
            paragaph1_2={curerntContent.pjd_arkanoid_p12}
            image1={Arkanoid1}

            title2={curerntContent.pjd_title_learning}
            paragaph2_1={curerntContent.pjd_arkanoid_p21}
            paragaph2_2={curerntContent.pjd_arkanoid_p22}
            image2={Arkanoid2}

            watchVideoLink={'https://youtu.be/Hf_ILvAUNXk'}
            // itchLink={}
            duration={'2 d'}
            setIsPrimaryColorNavbar={setIsPrimaryColorNavbar}
            platform={'windows'}
          />} />
          <Route path='/projects/tinydinorunner' element={<ProjectDetails
            language={language}
            projectTitle={'Tiny Dino Runner'}
            releaseYear={'2022'}
            status={curerntContent.pjd_status_finished}
            projectHeroImage={'bg-hero-tinydinorunner'}
            title1={curerntContent.pjd_title_project}
            paragaph1_1={curerntContent.pjd_tinydino_p11}
            paragaph1_2={curerntContent.pjd_tinydino_p12}
            image1={TinyDinoRunner2}
            title2={curerntContent.pjd_title_background}
            paragaph2_1={curerntContent.pjd_tinydino_p21}
            paragaph2_2={curerntContent.pjd_tinydino_p22}
            image2={TinyDinoRunner1}
            title3={curerntContent.pjd_title_recap}
            paragaph3_1={curerntContent.pjd_tinydino_p31}
            paragaph3_2={curerntContent.pjd_tinydino_p32}
            image3={TinyDinoRunner3}
            watchVideoLink={'https://youtu.be/-tg6yMWveXU'}
            itchLink={'https://kiny.itch.io/tinydinorunner'}
            isDark={true}
            duration={'14 d'}
            setIsPrimaryColorNavbar={setIsPrimaryColorNavbar}
            platform={'web'}
          />} />
          <Route path='/projects/proceduralcreatures' element={<ProjectDetails
            language={language}
            projectTitle={'Procedural Creatures'}
            releaseYear={'2022'}
            status={curerntContent.pjd_status_finished}
            projectHeroImage={'bg-hero-procedural'}
            title1={curerntContent.pjd_title_project}
            paragaph1_1={curerntContent.pjd_proceduralcreatures_p11}
            paragaph1_2={curerntContent.pjd_proceduralcreatures_p12}
            image1={Procedural2}
            title2={curerntContent.pjd_title_background}
            paragaph2_1={curerntContent.pjd_proceduralcreatures_p21}
            paragaph2_2={curerntContent.pjd_proceduralcreatures_p22}
            image2={Procedural1}
            title3={curerntContent.pjd_title_recap}
            paragaph3_1={curerntContent.pjd_proceduralcreatures_p31}
            paragaph3_2={curerntContent.pjd_proceduralcreatures_p32}
            image3={Procedural3}
            watchVideoLink={'https://youtu.be/1M0pr4jCZ88'}
            duration={'30 d'}
            setIsPrimaryColorNavbar={setIsPrimaryColorNavbar}
            platform={'windows'}
          />} />
          <Route path='/projects/stormrush' element={<ProjectDetails
            language={language}
            projectTitle={'Project: Storm Rush'}
            releaseYear={'2023'}
            status={curerntContent.pjd_status_finished}
            projectHeroImage={'bg-hero-stormrush'}
            title1={curerntContent.pjd_title_project}
            paragaph1_1={curerntContent.pjd_stormrush_p11}
            paragaph1_2={curerntContent.pjd_stormrush_p12}
            image1={StormRush1}
            title2={curerntContent.pjd_title_background}
            paragaph2_1={curerntContent.pjd_stormrush_p21}
            paragaph2_2={curerntContent.pjd_stormrush_p22}
            image2={StormRush2}
            title3={curerntContent.pjd_title_recap}
            paragaph3_1={curerntContent.pjd_stormrush_p31}
            paragaph3_2={curerntContent.pjd_stormrush_p32}
            image3={StormRush3}
            watchVideoLink={'https://youtu.be/E44h_tRNaYE'}
            duration={'100 d'}
            setIsPrimaryColorNavbar={setIsPrimaryColorNavbar}
            platform={'vr'}
          />} />
          <Route path='/projects/proceduralgeneration' element={<ProjectDetails
            language={language}
            projectTitle={'Procedural Generation'}
            releaseYear={'2023'}
            status={curerntContent.pjd_status_finished}
            projectHeroImage={'bg-hero-proceduralgeneration'}
            title1={curerntContent.pjd_title_project}
            paragaph1_1={curerntContent.pjd_proceduralgeneration_p11}
            paragaph1_2={curerntContent.pjd_proceduralgeneration_p12}
            image1={ProceduralGeneration1}
            title2={curerntContent.pjd_title_recap}
            paragaph2_1={curerntContent.pjd_proceduralgeneration_p21}
            paragaph2_2={curerntContent.pjd_proceduralgeneration_p22}
            image2={ProceduralGeneration2}
            duration={'90 d'}
            setIsPrimaryColorNavbar={setIsPrimaryColorNavbar}
            platform={'Unity'}
          />} />
          <Route path='/projects/footgununderground' element={<ProjectDetails
            language={language}
            projectTitle={'Footgun Underground'}
            releaseYear={'2024'}
            status={curerntContent.pjd_status_development}
            projectHeroImage={'bg-hero-footgun'}
            title1={curerntContent.pjd_title_project}
            paragaph1_1={curerntContent.pjd_footgun_p11}
            paragaph1_2={curerntContent.pjd_footgun_p12}
            image1={Footgun1}
            title2={curerntContent.pjd_title_background}
            paragaph2_1={curerntContent.pjd_footgun_p21}
            image2={Footgun2}
            // watchVideoLink={'https://www.youtube.com/watch?v=7FT2EM3cnrQ'}
            steamLink={'https://store.steampowered.com/app/2206240/Footgun_Underground/'}
            duration={'?'}
            setIsPrimaryColorNavbar={setIsPrimaryColorNavbar}
            platform={'?'}
          />} />

          <Route path='/contact' element={<Contact language={language} setIsPrimaryColorNavbar={setIsPrimaryColorNavbar} />} />
          <Route path='/socials' element={<Socials language={language} setIsPrimaryColorNavbar={setIsPrimaryColorNavbar} />} />
          <Route path='/about' element={<About language={language} setIsPrimaryColorNavbar={setIsPrimaryColorNavbar} />} />
          <Route path='*' element={<PageNotFound setIsPrimaryColorNavbar={setIsPrimaryColorNavbar} language={language} />} />
          {/* <Route path='' element={}/> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
