import React from 'react';
import { BsClipboard, BsClipboardCheck } from 'react-icons/bs';

const EmailCard = ({ text }) => {

    const [isCopied, setIsCopied] = React.useState(false);

    function OnClickCopy() {
        navigator.clipboard.writeText(text);
        setIsCopied(true);
    }

    return (
        <div className='flex flex-row gap-2'>
            <a target='_blank' rel='noreferrer'
                className='px-4 py-2 w-full rounded-full text-white bg-white
                bg-opacity-10 duration-200 col-span-2 max-w-sm'>
                <div className='flex flex-row items-center gap-2'>

                    <span className='text-base lg:text-base font-mono text-left'>
                        {text}
                    </span>

                </div>
            </a>

            <div className='w-fit px-4 py-2 rounded-full text-white bg-white
                bg-opacity-10 duration-200 flex flex-row gap-2 items-center 
                justify-center max-w-sm'>
                <div className='hover:text-primary duration-200'
                    onClick={() => OnClickCopy()}>
                    {
                        isCopied ?
                            <BsClipboardCheck size={24} /> :
                            <BsClipboard size={24} />
                    }
                </div>
            </div>
        </div>
    )
}

export default EmailCard