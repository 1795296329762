import React from 'react';
import HomeHeader from '../text/HomeHeader';
import NormalText from '../text/NormalText';
import RoundButton from './RoundButton';

const EmblaSlideDesktop = ({ title, text, slideRef, buttonText, link }) => {

    return (
        <div className='w-full h-screen flex flex-col justify-center snap-center snap-always'>
            <div ref={slideRef} className='text-left max-w-2xl w-[50%] ml-32'>
                <HomeHeader text={title} />
                <NormalText text={text} additionalStyle={'text-white'}/>

                <div className='w-full flex mt-5'>
                    <RoundButton text={buttonText} link={link} />
                </div>
            </div>
        </div>
    )
}

export default EmblaSlideDesktop