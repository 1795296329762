import React from 'react';
import { gsap } from 'gsap';
import { Link } from "react-router-dom";
import { Squeeze as Hamburger, Squeeze } from 'hamburger-react'
import NavButtonMobile from './NavButtonMobile';
import NavButtonDesktop from './NavButtonDesktop';
import { SiTwitter, SiInstagram, SiTwitch, SiDiscord } from 'react-icons/si';
import { MdLanguage } from 'react-icons/md';
import NavLanguageDropdown from './NavLanguageDropdown';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { content } from './content'; // Übersetzungen
import KinyLogo from '../media/KinyLogo';

const Navbar = ({ handleSetLanguage, language, isPrimary }) => {
    const [nav, setNav] = React.useState(false);
    const handleNav = () => { setNav(!nav); };
    const menuRef = React.useRef(null);

    /*Fade für den Menu Toggle*/
    React.useEffect(() => {
        if (nav) {
            document.body.classList.add("no_scroll"); // Disable Scroll Body

            gsap.to(menuRef.current, { duration: .2, opacity: 1 });
            menuRef.current.classList.remove('hidden');
            menuRef.current.classList.add('flex');
        }
        else {
            document.body.classList.remove("no_scroll"); // Enable Scroll Body

            gsap.to(menuRef.current, { duration: .2, opacity: 0 });

            const waitForAnimationEnd = async () => {
                await timeout(800);
                menuRef.current.classList.remove('flex');
                menuRef.current.classList.add('hidden');
            }

            waitForAnimationEnd();
        }
    }, [nav])

    /*Delay funcion die ein Promise wieder gibt damit auf diesen 
    gewartet werden kann*/
    function timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }

    // Window Listener - Handle Background Scroll wenn NavMenu offen
    const { height, width } = useWindowDimensions();
    // console.log(width);

    React.useEffect(() => {
        if (width < 1024) {
            if (nav && !document.body.classList.contains("no_scroll"))
                document.body.classList.add("no_scroll"); // Disable Scroll Body
        }
        else
            document.body.classList.remove("no_scroll"); // Enable Scroll Body

    }, [width])

    // Content übersetzt in allen verfügbaren Sprachen
    let currentContent = {}
    language === 'english' ? (currentContent = content.english) : (currentContent = content.german);

    return (
        <nav className='fixed top-0 z-50 w-full px-4 pt-5 lg:px-8 lg:pt-8'>

            {/* Mobile - Menu */}
            <div className='flex h-full border-b border-white items-center justify-between max-w-11xl mx-auto border-opacity-0 relative z-30 lg:hidden'>
                <Link to='/'>
                    <KinyLogo width='1.75rem' height='1.75rem' fill={isPrimary ? '#3BBC38' : '#fff'} stroke={isPrimary ? '#3BBC38' : '#fff'}/> {/*width = h7*/}
                </Link>

                <Squeeze
                    color={nav ? '#3BBC38' : (isPrimary ? '#3BBC38' : '#FFFFFF')}
                    size={28}
                    toggled={nav}
                    toggle={setNav}
                />
            </div>

            {/* Mobile - Menu Content */}
            <div ref={menuRef} className={'fixed top-0 left-0 w-full h-full bg-[#171717df] z-20 duration-700 backdrop-blur-md hidden flex-col px-6 pt-20 pb-20 overflow-scroll lg:hidden'}>
                <ul className='font-extrabold text-xl'>

                    <NavButtonMobile text={currentContent.menu_home} link={'/'} onClick={handleNav} />
                    <NavButtonMobile text={currentContent.menu_projects} link={'/projects'} onClick={handleNav} />
                    <NavButtonMobile text={currentContent.menu_about} link={"/about"} onClick={handleNav} />
                    <NavButtonMobile text={currentContent.menu_contact} link={"/contact"} onClick={handleNav} />

                    <NavLanguageDropdown text={currentContent.menu_language} icon={<MdLanguage />} onLanguageChange={(language) => { handleSetLanguage(language) }} />

                    <NavButtonMobile text={"Twitter"} icon={<SiTwitter />} href={'https://twitter.com/0xKiny'} />
                    <NavButtonMobile text={"Instagram"} icon={<SiInstagram />} href={'https://www.instagram.com/kinychanchan/'} />
                    <NavButtonMobile text={"Twitch"} icon={<SiTwitch />} href={'https://www.twitch.tv/kinychanchan'} />
                    <NavButtonMobile text={"Discord"} icon={<SiDiscord />} href={'https://discord.gg/wvnS5fHWKT'} />

                </ul>
            </div>

            {/* Desktop Menu Content */}
            <div className='hidden lg:flex h-full border-b border-white items-center justify-between max-w-11xl mx-auto border-opacity-0'>

                <div className='flex-shrink-[5] mr-2'>
                    <div className='flex'>

                        <div className={`p-2 rounded ${isPrimary ? 'bg-primary' : 'bg-white'} bg-opacity-0 hover:bg-opacity-20 duration-200`}>
                            <Link to='/'>
                                <KinyLogo width='1.75rem' height='1.75rem' fill={isPrimary ? '#3BBC38' : '#fff'}  stroke={isPrimary ? '#3BBC38' : '#fff'}/> {/*width = h-7*/}
                            </Link>
                        </div>

                    </div>
                </div>

                <div className='items-center  hidden lg:flex min-w-0'>
                    <ul className='flex space-x-2 min-w-0'>

                        <NavButtonDesktop text={currentContent.menu_home} link={"/"} isPrimary={isPrimary} />
                        <NavButtonDesktop text={currentContent.menu_projects} link={"/projects"} isPrimary={isPrimary} />
                        <NavButtonDesktop text={currentContent.menu_about} link={"/about"} isPrimary={isPrimary} />
                        <NavButtonDesktop text={currentContent.menu_contact} link={"/contact"} isPrimary={isPrimary} />

                        <NavLanguageDropdown text={currentContent.menu_language} icon={<MdLanguage color={isPrimary ? '#3BBC38' : '#FFF'} />} onLanguageChange={(language) => { handleSetLanguage(language) }} isPrimary={isPrimary} />

                        <NavButtonDesktop text={"Twitter"} icon={<SiTwitter />} href={'https://twitter.com/0xKiny'} isPrimary={isPrimary} />
                        <NavButtonDesktop text={"Instagram"} icon={<SiInstagram />} href={'https://www.instagram.com/kinychanchan/'} isPrimary={isPrimary} />
                        <NavButtonDesktop text={"Twitch"} icon={<SiTwitch />} href={'https://www.twitch.tv/kinychanchan'} isPrimary={isPrimary} />
                        <NavButtonDesktop text={"Discord"} icon={<SiDiscord />} href={'https://discord.gg/wvnS5fHWKT'} isPrimary={isPrimary} />

                    </ul>
                </div>

            </div>

        </nav>
    )
}

export default Navbar