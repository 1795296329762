import React from 'react'

const NormalText = ({ text, additionalStyle }) => {
    return (
        <p className={`pt-2 text-lg ${additionalStyle}`}>
            {text}
        </p>
    )
}

export default NormalText